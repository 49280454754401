import React, { useEffect, useState, useRef } from "react";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Box, Button } from "@mui/material";
import "./CustomAudioRecorder.css";
import CustomActionButton from "../CustomActionButton/CustomActionButton";
import SaveResultDialogBox from "../SaveResultDialogBox/SaveResultDialogBox";
import DeleteAudioDialog from "../DeleteAudioDialog/DeleteAudioDialog";
import uploadAudio from "../../FetchApi/uploadAudio";
import { UrlConstants } from "../../utils/commonUtils/constants";
import { formatTime } from "../../utils/commonUtils/commonUtils";
import CustomBackdrop from "../CustomBackdrop/CustomBackdrop";
import useFlutterParamValues from "../../utils/useFlutterParamValues";

const CustomAudioRecorder = ({
  isRecording,
  isStopped,
  isPaused,
  isResumed,
  audioBlob,
  recordingError,
  setAudioBlob,
  startRecording,
  stopRecording,
  pauseRecording,
  resumeRecording,
  hideSlider,
  setHideSlider,
  showResultLoadingScreen,
  setShowResultLoadingScreen,
  showOrfTestScreen,
  setShowOrfTestScreen,
  audioBlobForAudioPlayer,
  setAudioBlobForAudioPlayer,
  assessmentId,
  setAssessmentId,
  showHomeScreen,
  setShowHomeScreen,
  uploadAudioStatus,
  setUploadAudioStatus,
  cardClicked,
  setCardClicked,
  audioTestId,
  t,
}) => {
  const [showSaveResultDialog, setShowSaveResultDialog] = useState(false);
  const [showDeleteAudioDialog, setShowDeleteAudioDialog] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);
  const [isAudioUploading, setAudioUploading] = useState(false);
  const [time, setTime] = useState(0);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const abortController = useRef(null);
  let currInterval;

  const handleStartRecording = () => {
    setOpenBackdrop(true);
    setTimeout(() => {
      if (!openBackdrop) {
        setHideSlider(true);
        startRecording();
      }
    }, 5500);
  };

  const handleStopRecording = () => {
    stopRecording();
  };

  useEffect(() => {
    if (isRecording) {
      currInterval = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    }
    return () => {
      clearInterval(currInterval);
      setTime(0);
    };
  }, [isRecording]);

  useEffect(() => {
    if (saveClicked == true) {
      // setTimeout(() => {
      uploadAudio(
        UrlConstants.BASE_URL +
          UrlConstants.BASE_PATH_URL + UrlConstants.ASSESSMENT +
          `/${assessmentId}/upload?self_audio_validation=${audioTestId}`,
        audioBlobForAudioPlayer,
        abortController
      )
        .then((result) => {
          if (result["status"] != null || result["status"] != undefined) {
            setAudioUploading(false);
            setUploadAudioStatus(result["status"]);
          }
        })
        .catch((error) => {
          if (!abortController.current.signal.aborted) {
            setUploadAudioStatus("ERR_INTERNET_DISCONNECTED");
          }
        });
      // }, 3000);
    }
  }, [saveClicked]);

  return (
    <>
      <div>
        <CustomBackdrop
          openBackdrop={openBackdrop}
          setOpenBackdrop={setOpenBackdrop}
          t={t}
        />
      </div>
      <div>
        <SaveResultDialogBox
          isDialogOpen={showSaveResultDialog}
          setDialogOpen={setShowSaveResultDialog}
          showResultLoadingScreen={showResultLoadingScreen}
          setShowResultLoadingScreen={setShowResultLoadingScreen}
          showOrfTestScreen={showOrfTestScreen}
          setShowOrfTestScreen={setShowOrfTestScreen}
          audioBlob={audioBlob}
          setAudioBlob={setAudioBlob}
          hideSlider={hideSlider}
          setHideSlider={setHideSlider}
          showHomeScreen={showHomeScreen}
          setShowHomeScreen={setShowHomeScreen}
          uploadAudioStatus={uploadAudioStatus}
          setUploadAudioStatus={setUploadAudioStatus}
          isAudioUploading={isAudioUploading}
          setAudioUploading={setAudioUploading}
          saveClicked={saveClicked}
          setSaveClicked={setSaveClicked}
          cardClicked={cardClicked}
          setCardClicked={setCardClicked}
          abortController={abortController}
          t={t}
        />
        <DeleteAudioDialog
          isDialogOpen={showDeleteAudioDialog}
          setDialogOpen={setShowDeleteAudioDialog}
          hideSlider={hideSlider}
          setHideSlider={setHideSlider}
          audioBlob={audioBlob}
          setAudioBlob={setAudioBlob}
          uploadAudioStatus={uploadAudioStatus}
          setUploadAudioStatus={setUploadAudioStatus}
          t={t}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: "30px",
          marginBottom: "16px",
          alignItems: "end",
        }}
      >
        {isStopped && audioBlob != null && (
          // hideSlider == true
          <div
            style={{
              width: "100px",
              textAlign: "center",
              // pointerEvents: hideSlider && "none"
            }}
            onClick={() => setShowDeleteAudioDialog(true)}
          >
            <CustomActionButton
              children={<CloseOutlinedIcon />}
              text={t("cancel")}
            />
          </div>
        )}
        <div>
          <Box>
            {isRecording && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "2px",
                }}
              >
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    backgroundColor: "#E70000",
                    borderRadius: "50%",
                    marginRight: "8px",
                    animation: "flashing-recording-dot 1s infinite",
                  }}
                />
                <div
                  style={{
                    color: "#000",
                    fontSize: "20px",
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "150%",
                    letterSpacing: "-0.38px",
                  }}
                >
                  {formatTime(time)}
                </div>
              </div>
            )}
            <div
              style={{
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              <Button
                variant={isRecording ? "outlined" : "contained"}
                style={{
                  textTransform: "capitalize",
                  borderRadius: "12px",
                  color: isRecording ? "#2573D9" : "#fff",
                  fontWeight: "500",
                  fontSize: "20px",
                  fontFamily: "Open Sans",
                  padding: "2px 18px",
                  letterSpacing: "-0.38px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                disabled={audioBlob != null && isStopped ? true : false}
                disableElevation
                onClick={() => {
                  if (isRecording) {
                    handleStopRecording();
                  } else if (isPaused) {
                    resumeRecording();
                  } else {
                    handleStartRecording();
                  }
                }}
              >
                {isStopped && audioBlob == null ? (
                  <>{t("start")}</>
                ) : isRecording ? (
                  <>{t("stop")}</>
                ) : (
                  <>{t("stop")}</>
                )}
              </Button>
            </div>
          </Box>
        </div>
        {isStopped && audioBlob != null && (
          // hideSlider == true
          <div
            style={{
              width: "100px",
              textAlign: "center",
              // pointerEvents: hideSlider ? "none"
            }}
            onClick={() => {
              setAudioUploading(true);
              setShowSaveResultDialog(true);
              setSaveClicked(true);
            }}
          >
            <CustomActionButton
              children={<DoneOutlinedIcon />}
              text={t("save")}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default CustomAudioRecorder;
