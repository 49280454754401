import React from "react";
import MicIcon from "@mui/icons-material/Mic";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import CustomDialog from "../CustomDialog/CustomDialog";
import savedSuccessfullyImage from "../../../assets/saved_successfully_image.svg";
import CustomDialogContent from "../CustomDialogContent/CustomDialogContent";
import "./MicrophoneWorkingFineDialog.css";
import { Button } from "@mui/material";
import useFlutterEvent from "../../../utils/useFlutterEvent";

const MicrophoneWorkingFineDialog = ({
  open,
  setOpen,
  showAudioTestingScreen,
  setShowAudioTestingScreen,
  showOrfTestScreen,
  setShowOrfTestScreen,
  audioTestId,
  t,
}) => {
  const { sendOrfAudioTestFeedbackResult } = useFlutterEvent();
  return (
    <CustomDialog openDialog={open}>
      <CustomDialogContent>
        <div
          style={{
            backgroundImage: `url(${savedSuccessfullyImage})`,
            backgroundSize: "cover",
          }}
        >
          <div className="mic-working-fine-container-icon">
            <MicIcon
              style={{
                fontSize: "3rem",
                color: "#373737",
              }}
            />
            <CheckCircleIcon
              style={{
                position: "absolute",
                fontSize: "1.2rem",
                color: "#7EB66B",
                left: "49.6%",
                bottom: "38px",
              }}
            />
          </div>
          <div className="yay-text">{t("wohoo")}</div>
        </div>
        <div className="mic-working-desc">
          {t("microphone_working_perfectly")}
        </div>
        <div
          style={{
            margin: `30px 4% 24px`,
          }}
        >
          <Button
            variant="contained"
            endIcon={<EastOutlinedIcon />}
            style={{
              height: "48px",
              textTransform: "none",
              width: "100%",
              borderRadius: "8px",
              fontWeight: "400",
              fontSize: "16px",
              textAlign: "center",
              whiteSpace: "nowrap",
              color: "#FFF",
              fontFamily: "Open Sans",
              lineHeight: "150%",
              letterSpacing: "-0.304px",
              border: "1px solid #2573D9",
            }}
            disableElevation
            onClick={() => {
              setShowAudioTestingScreen(false);
              setShowOrfTestScreen(true);
              sendOrfAudioTestFeedbackResult(audioTestId);
            }}
          >
            {t("assessment")}
          </Button>
        </div>
      </CustomDialogContent>
    </CustomDialog>
  );
};

export default MicrophoneWorkingFineDialog;
