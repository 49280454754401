import React, { useEffect } from "react";
import { useState } from "react";
import { Box, Button } from "@mui/material";
import Header from "../../components/Header/Header";
import RecordVoiceOverOutlinedIcon from "@mui/icons-material/RecordVoiceOverOutlined";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import "./AudioTestingScreen.css";
import useAudioRecorder from "../../utils/useAudioRecorder";
import { formatTime } from "../../utils/commonUtils/commonUtils";
import OnboardingDrawer from "../../components/Drawer/OnboardingDrawer/OnboardingDrawer";
import AudioTestFeedbackDrawer from "../../components/Drawer/AudioTestFeedbackDrawer/AudioTestFeedbackDrawer";
import AudioQualityPoorDialog from "../../components/Dialog/AudioQualityPoorDialog/AudioQualityPoorDialog";
import MicrophoneWorkingFineDialog from "../../components/Dialog/MicrophoneWorkingFineDialog/MicrophoneWorkingFineDialog";
import OutOfChancesDialog from "../../components/Dialog/OutOfChancesDialog/OutOfChancesDialog";
import { useRef } from "react";
import uploadAudio from "../../FetchApi/uploadAudio";
import { UrlConstants } from "../../utils/commonUtils/constants";
import fetchStatus from "../../FetchApi/fetchStatus";
import CustomLoadingDialog from "../../components/CustomLoadingDialog/CustomLoadingDialog";
import DelayResponseDialog from "../../components/Dialog/DelayResponseDialog/DelayResponseDialog";
import useFlutterEvent from "../../utils/useFlutterEvent";
import ErrorDialogBox from "../../components/Dialog/ErrorDialogBox/ErrorDialogBox";
import useFlutterParamValues from "../../utils/useFlutterParamValues";
import createAssessmentApi from "../../FetchApi/createAssessmentApi";
import AudioSizeShortError from "../../components/AudioSizeShortError/AudioSizeShortError";

const AudioTestingScreen = ({
  showOrfTestScreen,
  setShowOrfTestScreen,
  showAudioTestingScreen,
  setShowAudioTestingScreen,
  audioQualityFeedback,
  setAudioQualityFeedback,
  audioTestId,
  setAudioTestId,
  t,
}) => {
  const {
    isRecording,
    isStopped,
    isPaused,
    isResumed,
    audioBlob,
    duration,
    setAudioBlob,
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
  } = useAudioRecorder();

  const { teacherId, deviceId } = useFlutterParamValues();
  const { sendOrfAudioTestFeedbackResult, closeWebView } = useFlutterEvent();
  const [time, setTime] = useState(0);
  const [openOnboardingDrawer, setOpenOnboardingDrawer] = useState(true);
  const [openFeedbackDrawer, setOpenFeedbackDrawer] = useState(false);
  const [chances, setChances] = useState(1);
  const [submitAudio, setSubmitAudio] = useState(false);
  const [isTestAudioProcessing, setTestAudioProcessing] = useState(false);
  const [uploadTestAudioStatus, setUploadTestAudioStatus] = useState(false);
  const [processedAudioTestResultStatus, setProcessedAudioTestResultStatus] =
    useState(null);
  const [processedAudioTestResult, setProcessedAudioTestResult] =
    useState(null);
  const [openResponseDialog, setOpenResponseDialog] = useState(false);
  const [audioValidationCreatingStatus, setAudioValidationCreatingStatus] =
    useState(null);
  const [retryClicked, setRetryClicked] = useState(false);
  const [openAudioShortDialog, setOpenAudioShortDialog] = useState(false);
  const [pointerEvents, setPointerEvents] = useState("auto");

  const abortController = useRef(null);
  let currInterval;

  useEffect(() => {
    if (isRecording) {
      currInterval = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    }
    return () => {
      clearInterval(currInterval);
      setTime(0);
    };
  }, [isRecording]);

  useEffect(() => {
    if (submitAudio == true) {
      // setTimeout(() => {
      uploadAudio(
        UrlConstants.BASE_URL +
          UrlConstants.BASE_PATH_URL +
          UrlConstants.VALIDATION +
          `/${audioTestId}/upload`,
        audioBlob,
        abortController
      )
        .then((result) => {
          if (result["status"] != null || result["status"] != undefined) {
            if (result["status"] === "ERROR") {
              setTestAudioProcessing(false);
              setOpenResponseDialog(true);
              setUploadTestAudioStatus(result["status"]);
            }
            setOpenResponseDialog(true);
            setUploadTestAudioStatus(result["status"]);
          }
        })
        .catch((error) => {
          if (!abortController.current.signal.aborted) {
            setTestAudioProcessing(false);
            setOpenResponseDialog(true);
            setUploadTestAudioStatus("ERR_INTERNET_DISCONNECTED");
          }
        });
      // }, 3000);
    }
  }, [submitAudio]);

  useEffect(() => {
    if (uploadTestAudioStatus === "COMPLETED") {
      // setTimeout(() => {
      fetchStatus(
        UrlConstants.BASE_URL +
          UrlConstants.BASE_PATH_URL +
          UrlConstants.VALIDATION +
          `/${audioTestId}`,
        abortController,
        30000,
        5000
      )
        .then((data) => {
          if (data.status === "COMPLETED") {
            setTestAudioProcessing(false);
            setOpenResponseDialog(true);
            setProcessedAudioTestResult(data.data);
            setProcessedAudioTestResultStatus(data.status);
          } else if (data.status === "UNDETERMINED") {
            setTestAudioProcessing(false);
            setOpenResponseDialog(true);
            setProcessedAudioTestResultStatus(data.status);
          } else if (data.status === "FAILED") {
            setTestAudioProcessing(false);
            setOpenResponseDialog(true);
            setProcessedAudioTestResult(data.data);
            setProcessedAudioTestResultStatus(data.status);
          } else if (data.status === "ERROR") {
            setTestAudioProcessing(false);
            setOpenResponseDialog(true);
            setProcessedAudioTestResultStatus(data.status);
          }
        })
        .catch((error) => {
          setTestAudioProcessing(false);
          setOpenResponseDialog(true);
          setProcessedAudioTestResultStatus(error.status);
        });
      // }, 3000);
    }
  }, [uploadTestAudioStatus]);

  useEffect(() => {
    if (retryClicked) {
      createAssessmentApi(
        UrlConstants.BASE_URL +
          UrlConstants.BASE_PATH_URL +
          UrlConstants.VALIDATION,
        {
          teacher_id: teacherId,
          device_id: deviceId,
        }
      )
        .then((response) => {
          if (response.status === "ERROR") {
            setTestAudioProcessing(false);
            setOpenResponseDialog(true);
            setAudioValidationCreatingStatus(response.status);
            setRetryClicked(false);
          } else {
            setTestAudioProcessing(false);
            setOpenResponseDialog(true);
            setAudioValidationCreatingStatus(response);
            setAudioTestId(response["id"]);
            setRetryClicked(false);
          }
        })
        .catch((error) => {
          setTestAudioProcessing(false);
          setOpenResponseDialog(true);
          setAudioValidationCreatingStatus("ERR_INTERNET_DISCONNECTED");
          setRetryClicked(false);
        });
    }
  }, [retryClicked]);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      if (
        duration === undefined ||
        (audioBlob !== null && duration === null) ||
        (duration !== null && duration <= 5)
      ) {
        setOpenAudioShortDialog(true);
        setOpenFeedbackDrawer(false);
      }
    }
  }, [duration]);

  useEffect(() => {
    if (isRecording) {
      setPointerEvents("none");
      setTimeout(() => {
        setPointerEvents("auto");
      }, 800);
    }
  }, [isRecording]);

  // useEffect(() => {
  //   if (
  //     openResponseDialog &&
  //     processedAudioTestResultStatus === "COMPLETED" &&
  //     processedAudioTestResult["is_valid"] === true
  //   ) {
  //     setTimeout(() => {
  //       setShowAudioTestingScreen(false);
  //       setShowOrfTestScreen(true);
  //       sendOrfAudioTestFeedbackResult(audioTestId);
  //     }, 2000);
  //   }
  // }, [openResponseDialog, processedAudioTestResultStatus]);

  return (
    <>
      <OnboardingDrawer
        openDrawer={openOnboardingDrawer}
        setOpenDrawer={setOpenOnboardingDrawer}
        t={t}
      />

      <AudioTestFeedbackDrawer
        openDrawer={openFeedbackDrawer}
        setOpenDrawer={setOpenFeedbackDrawer}
        submitAudio={submitAudio}
        setSubmitAudio={setSubmitAudio}
        isTestAudioProcessing={isTestAudioProcessing}
        setTestAudioProcessing={setTestAudioProcessing}
        audioBlob={audioBlob}
        setAudioBlob={setAudioBlob}
        t={t}
      />

      <AudioSizeShortError
        isDialogOpen={openAudioShortDialog}
        setDialogOpen={setOpenAudioShortDialog}
        warningHeading={t("audio_size_small_heading")}
        warningMessage={t("audio_size_small_text")}
        onClickRecordAgain={() => {
          setOpenAudioShortDialog(false);
          setAudioBlob(null);
        }}
        t={t}
      />

      {!isTestAudioProcessing &&
        (uploadTestAudioStatus === "ERROR" ||
          processedAudioTestResultStatus === "ERROR" ||
          audioValidationCreatingStatus === "ERROR" ||
          uploadTestAudioStatus === "ERR_INTERNET_DISCONNECTED" ||
          processedAudioTestResultStatus === "ERR_INTERNET_DISCONNECTED" ||
          audioValidationCreatingStatus === "ERR_INTERNET_DISCONNECTED") && (
          <ErrorDialogBox
            isDialogOpen={openResponseDialog}
            setDialogOpen={setOpenResponseDialog}
            showRetryButton={true}
            showAllStudentsButton={true}
            onClickRetryButton={() => {
              if (
                audioValidationCreatingStatus === "ERR_INTERNET_DISCONNECTED" ||
                audioValidationCreatingStatus === "ERROR"
              ) {
                setRetryClicked(true);
                setAudioValidationCreatingStatus(null);
                setTestAudioProcessing(true);
                setOpenFeedbackDrawer(false);
                setAudioBlob(null);
                setSubmitAudio(false);
                setUploadTestAudioStatus(null);
                setProcessedAudioTestResultStatus(null);
                setProcessedAudioTestResult(null);
              } else {
                setOpenFeedbackDrawer(false);
                setAudioBlob(null);
                setSubmitAudio(false);
                setUploadTestAudioStatus(null);
                setProcessedAudioTestResultStatus(null);
                setProcessedAudioTestResult(null);
              }
            }}
            onClickAllStudentsButton={() => {
              closeWebView();
            }}
            t={t}
          />
        )}

      {isTestAudioProcessing && (
        <CustomLoadingDialog
          heading={retryClicked ? t("please_wait") : t("processing_audio")}
          dialogDescription={!retryClicked && t("wait_till_process_audio")}
          isDialogOpen={isTestAudioProcessing}
        />
      )}

      {chances !== 0 &&
        !isTestAudioProcessing &&
        (processedAudioTestResultStatus === "COMPLETED" ||
          processedAudioTestResultStatus === "FAILED") &&
        processedAudioTestResult["is_valid"] === false && (
          <AudioQualityPoorDialog
            open={openResponseDialog}
            setOpen={setOpenResponseDialog}
            openFeedbackDrawer={openFeedbackDrawer}
            setOpenFeedbackDrawer={setOpenFeedbackDrawer}
            audioBlob={audioBlob}
            setAudioBlob={setAudioBlob}
            chances={chances}
            setChances={setChances}
            submitAudio={submitAudio}
            setSubmitAudio={setSubmitAudio}
            uploadTestAudioStatus={uploadTestAudioStatus}
            setUploadTestAudioStatus={setUploadTestAudioStatus}
            processedAudioTestResultStatus={processedAudioTestResultStatus}
            setProcessedAudioTestResultStatus={
              setProcessedAudioTestResultStatus
            }
            processedAudioTestResult={processedAudioTestResult}
            setProcessedAudioTestResult={setProcessedAudioTestResult}
            retryClicked={retryClicked}
            setRetryClicked={setRetryClicked}
            isTestAudioProcessing={isTestAudioProcessing}
            setTestAudioProcessing={setTestAudioProcessing}
            t={t}
          />
        )}

      {chances === 0 &&
        !isTestAudioProcessing &&
        (processedAudioTestResultStatus === "COMPLETED" ||
          processedAudioTestResultStatus === "FAILED") &&
        processedAudioTestResult["is_valid"] === false && (
          <OutOfChancesDialog
            open={openResponseDialog}
            setOpen={setOpenResponseDialog}
            showAudioTestingScreen={showAudioTestingScreen}
            setShowAudioTestingScreen={setShowAudioTestingScreen}
            showOrfTestScreen={showOrfTestScreen}
            setShowOrfTestScreen={setShowOrfTestScreen}
            audioTestId={audioTestId}
            t={t}
          />
        )}

      {!isTestAudioProcessing &&
        processedAudioTestResultStatus === "COMPLETED" &&
        processedAudioTestResult["is_valid"] === true && (
          <MicrophoneWorkingFineDialog
            open={openResponseDialog}
            setOpen={setOpenResponseDialog}
            showAudioTestingScreen={showAudioTestingScreen}
            setShowAudioTestingScreen={setShowAudioTestingScreen}
            showOrfTestScreen={showOrfTestScreen}
            setShowOrfTestScreen={setShowOrfTestScreen}
            audioTestId={audioTestId}
            t={t}
          />
        )}

      {!isTestAudioProcessing &&
        processedAudioTestResultStatus === "UNDETERMINED" && (
          <DelayResponseDialog
            open={true}
            titleDescription={t("assess_other_student")}
            buttonContent={t("assessment")}
            endIcon={<EastOutlinedIcon />}
            onButtonClick={() => {
              setShowAudioTestingScreen(false);
              setShowOrfTestScreen(true);
              sendOrfAudioTestFeedbackResult(audioTestId);
            }}
          />
        )}

      <Header heading={t("orf_test")} color="#373737" hideArrow={false} />

      <div className="check-microphone-quality-text">
        {t("check_microphone_quality")}
      </div>

      <div className="speak-the-following-container">
        <div
          style={{
            color: "#848484",
            fontSize: "10",
          }}
        >
          <RecordVoiceOverOutlinedIcon
            sx={{
              fontSize: "28px",
            }}
          />
        </div>
        <div className="speak-the-following-text">
          {t("speak_the_following")}
        </div>
      </div>

      <div className="text-to-read-container">
        <p className="text-to-read">{t("audio_test_paragraph")}</p>
      </div>
      <div className="divider">
        <div>
          <Box>
            {isRecording && (
              <div className="timer-container">
                <div
                  style={{
                    animation: "flashing-recording-dot 1s infinite",
                  }}
                  className="recording-dot"
                />
                <div className="timer">{formatTime(time)}</div>
              </div>
            )}
            <div
              style={{
                textAlign: "center",
                marginTop: !isRecording && "50px",
              }}
            >
              <Button
                variant={isRecording ? "outlined" : "contained"}
                style={{
                  textTransform: "capitalize",
                  borderRadius: "12px",
                  color: isRecording ? "#2573D9" : "#fff",
                  fontWeight: "500",
                  fontSize: "20px",
                  fontFamily: "Open Sans",
                  padding: "2px 18px",
                  letterSpacing: "-0.38px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  pointerEvents: pointerEvents,
                }}
                className="audio-recording-button"
                disabled={audioBlob != null && isStopped ? true : false}
                disableElevation
                onClick={() => {
                  if (isRecording) {
                    stopRecording();
                    setOpenFeedbackDrawer(true);
                  } else if (isPaused) {
                    resumeRecording();
                  } else {
                    startRecording();
                  }
                }}
              >
                {isStopped && audioBlob == null ? (
                  <>{t("start")}</>
                ) : isRecording ? (
                  <>{t("stop")}</>
                ) : (
                  <>{t("stop")}</>
                )}
              </Button>
            </div>
          </Box>
        </div>
        <div className="tap-button-instruct-text">
          {isRecording
            ? t("intruct_to_tap_for_stop_recording")
            : t("intruct_to_tap_for_start_recording")}
        </div>
      </div>
    </>
  );
};

export default AudioTestingScreen;
