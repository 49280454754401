import React, { useState, useRef } from "react";
import useAudioRecorder from "../../utils/useAudioRecorder";
import CustomAudioRecorder from "../CustomAudioRecorder/CustomAudioRecorder";
import { useEffect } from "react";
import AudioSizeShortError from "../AudioSizeShortError/AudioSizeShortError";

const AudioRecorder = ({
  hideSlider,
  setHideSlider,
  hideArrow,
  setHideArrow,
  showResultLoadingScreen,
  setShowResultLoadingScreen,
  showOrfTestScreen,
  setShowOrfTestScreen,
  audioBlobForAudioPlayer,
  setAudioBlobForAudioPlayer,
  assessmentId,
  setAssessmentId,
  showHomeScreen,
  setShowHomeScreen,
  uploadAudioStatus,
  setUploadAudioStatus,
  cardClicked,
  setCardClicked,
  audioTestId,
  t,
}) => {
  const [openDialogForError, setOpenDialogForError] = useState(false);
  const {
    isRecording,
    isStopped,
    isPaused,
    isResumed,
    audioBlob,
    recordingError,
    duration,
    setAudioBlob,
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
  } = useAudioRecorder();

  if (isRecording) {
    setHideArrow(true);
  } else {
    setHideArrow(false);
    setAudioBlobForAudioPlayer(audioBlob);
  }

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      if (duration === undefined || (duration !== null && duration <= 5)) {
        setOpenDialogForError(true);
      }
    }
  }, [duration]);

  return (
    <>
      <AudioSizeShortError
        isDialogOpen={openDialogForError}
        setDialogOpen={setOpenDialogForError}
        warningHeading={t("audio_size_small_heading")}
        warningMessage={t("audio_size_small_text")}
        onClickRecordAgain={() => {
          setOpenDialogForError(false);
          setHideSlider(false);
          setAudioBlobForAudioPlayer(null);
          setAudioBlob(null);
        }}
        t={t}
      />

      <CustomAudioRecorder
        isRecording={isRecording}
        isStopped={isStopped}
        isPaused={isPaused}
        isResumed={isResumed}
        audioBlob={audioBlob}
        recordingError={recordingError}
        setAudioBlob={setAudioBlob}
        startRecording={startRecording}
        stopRecording={stopRecording}
        pauseRecording={pauseRecording}
        resumeRecording={resumeRecording}
        hideSlider={hideSlider}
        setHideSlider={setHideSlider}
        showResultLoadingScreen={showResultLoadingScreen}
        setShowResultLoadingScreen={setShowResultLoadingScreen}
        showOrfTestScreen={showOrfTestScreen}
        setShowOrfTestScreen={setShowOrfTestScreen}
        audioBlobForAudioPlayer={audioBlobForAudioPlayer}
        setAudioBlobForAudioPlayer={setAudioBlobForAudioPlayer}
        assessmentId={assessmentId}
        setAssessmentId={setAssessmentId}
        showHomeScreen={showHomeScreen}
        setShowHomeScreen={setShowHomeScreen}
        uploadAudioStatus={uploadAudioStatus}
        setUploadAudioStatus={setUploadAudioStatus}
        cardClicked={cardClicked}
        setCardClicked={setCardClicked}
        audioTestId={audioTestId}
        t={t}
      />
    </>
  );
};

export default AudioRecorder;
