import React from "react";
import clockImg from "../../../assets/clock_img.svg";
import CustomDialog from "../CustomDialog/CustomDialog";
import CustomDialogContent from "../CustomDialogContent/CustomDialogContent";
import "./DelayResponseDialog.css";
import { Button } from "@mui/material";

const DelayResponseDialog = ({
  open,
  setOpen,
  titleMessage,
  titleDescription,
  buttonContent,
  startIcon,
  endIcon,
  onButtonClick,
}) => {
  return (
    <CustomDialog openDialog={open}>
      <CustomDialogContent>
        <div
          className="timer-icon-container"
          style={{
            marginTop: titleMessage ? "5%" : "13%",
          }}
        >
          <img src={clockImg} alt="clock-img" />
        </div>
        <div className="deplay-response-title">{titleMessage}</div>
        <div className="deplay-response-desc">{titleDescription}</div>
        <div
          style={{
            margin: `${titleMessage ? "-10px" : "52px"} 4% 24px`,
          }}
        >
          <Button
            variant="contained"
            startIcon={startIcon}
            endIcon={endIcon}
            style={{
              height: "48px",
              textTransform: "none",
              width: "100%",
              borderRadius: "12px",
              fontWeight: "400",
              fontSize: "16px",
              textAlign: "center",
              whiteSpace: "nowrap",
              color: "#FFF",
              fontFamily: "Open Sans",
              lineHeight: "150%",
              letterSpacing: "-0.304px",
              border: "1px solid #2573D9",
            }}
            disableElevation
            onClick={() => {
              onButtonClick();
            }}
          >
            {buttonContent}
          </Button>
        </div>
      </CustomDialogContent>
    </CustomDialog>
  );
};

export default DelayResponseDialog;
