import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import "./CustomLoadingDialog.css";
import CustomLoader from "../CustomLoader/CustomLoader";

const CustomLoadingDialog = ({
  isDialogOpen,
  setDialogOpen,
  heading,
  buttonFilledName,
  buttonFilledIcon,
  buttonTextName,
  buttonTextIcon,
  showButtonsInLoadingDialog,
  setShowButtonsInLoadingDialog,
  dialogDescription
}) => {
  return (
    <Dialog
      className="loadingDialog"
      open={isDialogOpen}
      aria-labelledby="loading_dialog-title"
      aria-describedby="loading_dialog-description"
      style={{ borderRadius: "20px" }}
    >
      <div className="loading_dialog-title">
        <DialogTitle id="loading_dialog-title">
          <div
            style={{
              textAlign: "center",
              marginBottom: "11px",
            }}
          >
            <Typography
              style={{
                fontFamily: "Open Sans",
                fontSize: "20px",
                fontWeight: "600",
                color: "#000000",
                letterSpacing: "-0.019em",
              }}
            >
              {heading}
            </Typography>
          </div>
          <div
            style={{
              textAlign: "center",
              padding: "1.5rem 0px 0px 0px",
            }}
          >
            <CustomLoader size={60} />
          </div>
        </DialogTitle>
      </div>
      <DialogContent>
        <DialogContentText id="feedback-dialog-description">
          {showButtonsInLoadingDialog ? (
            <div>
              <div
                style={{
                  marginTop: "18px",
                }}
              >
                <Button
                  style={{
                    textTransform: "capitalize",
                    borderRadius: "12px",
                    width: "100%",
                    height: "42px",
                  }}
                  variant="contained"
                  disableElevation
                  disabled
                  startIcon={buttonFilledIcon}
                >
                  <div
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "400",
                      fontSize: "16px",
                      letterSpacing: "-0.019em",
                      color: "#FFFFFF",
                    }}
                  >
                    {buttonFilledName}
                  </div>
                </Button>
              </div>
              <div
                style={{
                  marginTop: "8px",
                }}
              >
                <Button
                  style={{
                    textTransform: "capitalize",
                    width: "100%",
                    color: "#373737",
                  }}
                  variant="text"
                >
                  <div
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "400",
                      fontSize: "16px",
                      letterSpacing: "-0.019em",
                    }}
                  >
                    {buttonTextName}
                  </div>
                </Button>
              </div>
            </div>
          ) : (
            <div
              style={{
                color: "#000",
                textAlign: "center",
                fontSize: "20px",
                fontFamily: "Open Sans",
                lineHeight: "150%",
                letterSpacing: "-0.38px",
              }}
            >
              {dialogDescription}
            </div>
          )}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default CustomLoadingDialog;
