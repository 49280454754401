import { getMonthFromDate } from "./commonUtils";

const getGroupedAssessmentList = (assessmentList) => {
  const groupedAssessmentList = {};
  assessmentList.forEach((assessment) => {
    const month = getMonthFromDate(assessment.created_at, "long");

    // Check if the month key exists in the groupedData object
    if (groupedAssessmentList.hasOwnProperty(month)) {
      groupedAssessmentList[month].push(assessment);
    } else {
      groupedAssessmentList[month] = [assessment];
    }
  });

  const resultAssessmentList = Object.keys(groupedAssessmentList).map(
    (month) => ({
      month: month,
      assessments: groupedAssessmentList[month],
    })
  );

  return resultAssessmentList;
};

export default getGroupedAssessmentList;
