import ReactDOMServer from "react-dom/server";
import jwt_decode from "jwt-decode";

export function convertToTitleCase(inputString) {
  let delimiters = [",", "/"];
  let outputString = inputString
    .toLowerCase()
    .replace(new RegExp("[" + delimiters.join("") + "]", "g"), " ")
    .replace(/\s+/g, " ") // Remove extra spaces between words
    .replace(/(?:^|\s)\w/g, function (match) {
      return match.toUpperCase();
    });

  return outputString;
}

export function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds =
    seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60;
  return `${formattedMinutes}:${formattedSeconds}`;
}

export function convertComponentToString(component) {
  return ReactDOMServer.renderToString(component);
}

export function getDecodedPayload(token) {
  return jwt_decode(token);
}

export function getDayFromDate(date, format) {
  return new Date(date).toLocaleDateString("en-GB", {
    day: format,
  });
}

export function getMonthFromDate(date, format) {
  return new Date(date).toLocaleDateString("en-GB", { month: format });
}

export function getYearFromDate(date) {
  return new Date(date).getFullYear();
}

export function getFormattedDate(date, delimiter, dayFormat, monthFormat) {
  return `${getDayFromDate(date, dayFormat)}${delimiter}${getMonthFromDate(
    date,
    monthFormat
  )}${delimiter}${getYearFromDate(date)}`;
}
