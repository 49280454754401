import React from "react";
import "./AudioQualityPoorDialog.css";
import CustomDialog from "../CustomDialog/CustomDialog";
import CustomDialogContent from "../CustomDialogContent/CustomDialogContent";
import MicIcon from "@mui/icons-material/Mic";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Button, DialogActions, DialogContentText } from "@mui/material";
import AudioPlayer from "../../AudioPlayer/AudioPlayer";
import { useState } from "react";

const AudioQualityPoorDialog = ({
  open,
  setOpen,
  openFeedbackDrawer,
  setOpenFeedbackDrawer,
  audioBlob,
  setAudioBlob,
  chances, 
  setChances,
  submitAudio, 
  setSubmitAudio,
  uploadTestAudioStatus, 
  setUploadTestAudioStatus,
  processedAudioTestResultStatus, 
  setProcessedAudioTestResultStatus,
  processedAudioTestResult, 
  setProcessedAudioTestResult,
  retryClicked, 
  setRetryClicked,
  isTestAudioProcessing, 
  setTestAudioProcessing,
  t,
}) => {
  const [testedAudioElementRef, setTestedAudioElementRef] = useState(null);

  return (
    <CustomDialog openDialog={open}>
      <CustomDialogContent>
        <div
          style={{
            position: "relative",
            display: "inline-block",
            width: "100%",
            textAlign: "center",
            margin: "44px 0px 10px",
          }}
        >
          <MicIcon
            style={{
              fontSize: "2.5rem",
              color: "#373737",
            }}
          />
          <RemoveCircleIcon
            style={{
              position: "absolute",
              fontSize: "1rem",
              color: "#DC2727",
              left: "49.6%",
              bottom: "32px",
            }}
          />
        </div>
        <div className="audio-quality-poor-desc">
          {t("audio_quality_poor_message")}
        </div>
        {audioBlob && (
          <div
            style={{
              padding: "8px 14px 8px 8px",
              background: "rgba(252, 219, 219, 0.54)",
              borderRadius: "12px",
              margin: "12px 14px 24px 14px",
            }}
          >
            <AudioPlayer
              audioBlobForAudioPlayer={audioBlob}
              setAudioBlobForAudioPlayer={setAudioBlob}
              testedAudioElementRef={testedAudioElementRef}
              setTestedAudioElementRef={setTestedAudioElementRef}
            />
          </div>
        )}
        <div className="try-again-another-device-desc">
          {t("check_microphone_quality_again")}
        </div>
      </CustomDialogContent>
      <div
        style={{
          margin: "0px 4% 8%",
        }}
      >
        <Button
          variant="contained"
          style={{
            height:"44px",
            textTransform: "none",
            width: "100%",
            borderRadius: "12px",
            fontWeight: "400",
            fontSize: "16px",
            textAlign: "center",
            whiteSpace: "nowrap",
            color: "#FFF",
            fontFamily: "Open Sans",
            lineHeight: "150%",
            letterSpacing: "-0.304px",
            border: "1px solid #2573D9",
          }}
          //   disabled={isAudioUploading}
          disableElevation
          onClick={() => {
            setOpenFeedbackDrawer(false);
            setOpen(false);
            setRetryClicked(true);
            setTestAudioProcessing(true);
            setAudioBlob(null);
            setChances(chances-1);
            setSubmitAudio(false);
            setUploadTestAudioStatus(null);
            setProcessedAudioTestResultStatus(null);
            setProcessedAudioTestResult(null);
          }}
        >
          {t("retry")}
        </Button>
      </div>
    </CustomDialog>
  );
};

export default AudioQualityPoorDialog;
