import { Box, Slider } from "@mui/material";
import "./CustomSlider.css";
import Typography from "@mui/material/Typography";

function valuetext(value) {
  return `${value}`;
}
const CustomSlider = ({
  min,
  max,
  step,
  defaultValue,
  marks,
  valueLabelDisplay,
  setSliderValue = () => {},
  t
}) => {
  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <div className="text-size">
        <Typography gutterBottom>{t('text_size')}</Typography>
      </div>
      <div className="text-resize-slider">
        <Slider
          aria-label="Temperature"
          value={defaultValue ? defaultValue : 0}
          getAriaValueText={valuetext}
          valueLabelDisplay={valueLabelDisplay}
          step={step ? step : 1}
          marks={marks}
          min={min ? min : 0}
          max={max ? max : 0}
          onChange={handleSliderChange}
          style={{ padding: 0 }}
        />
      </div>
    </Box>
  );
};
export default CustomSlider;
