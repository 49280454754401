import { DialogContent } from "@mui/material";
import "./CustomDialogContent.css";
import React from "react";

const CustomDialogContent = ({ children }) => {
  return (
    <div className="custom-dialog-content">
      <DialogContent>{children}</DialogContent>
    </div>
  );
};

export default CustomDialogContent;
