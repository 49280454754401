import React from "react";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import speakAloudWhiteImg from "../../assets/speak_aloud_white_img.svg";
import micQualityWhiteImg from "../../assets/mic_quality_white_img.svg";
import speakAloudGreyImg from "../../assets/speak_aloud_grey_img.svg";
import micQualityGreyImg from "../../assets/mic_quality_grey_img.svg";
import "./QualityChecksInstruction.css";
import AudioQualityComponent from "./AudioQualityComponent/AudioQualityComponent";

const QualityChecksInstruction = ({
  headingText,
  borderColor,
  headingColor,
  contentColor,
  t,
}) => {
  return (
    <div
      style={{
        borderRadius: "16px",
        border: `1px solid ${borderColor}`,
      }}
      className="container"
    >
      <div
        style={{
          color: `${headingColor}`,
        }}
        className="instruction-heading"
      >
        {headingText}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginBottom: "22px",
          marginTop: "12px",
        }}
      >
        <AudioQualityComponent
          icon={<VolumeOffIcon />}
          text={t("no_noice")}
          color={contentColor}
        />
        <AudioQualityComponent
          icon={
            <img
              src={
                contentColor === "#FFF" ? speakAloudWhiteImg : speakAloudGreyImg
              }
            />
          }
          text={t("speak_aloud")}
          color={contentColor}
        />
        <AudioQualityComponent
          icon={
            <img
              src={
                contentColor === "#FFF" ? micQualityWhiteImg : micQualityGreyImg
              }
              style={{
                width: "20px",
              }}
            />
          }
          text={t("mic_quality")}
          color={contentColor}
        />
      </div>
    </div>
  );
};

export default QualityChecksInstruction;
