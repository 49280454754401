import React, { useState } from "react";
import { Button } from "@mui/material";
import CustomCard from "../../components/CustomCard/CustomCard";
import AudioPlayer from "../../components/AudioPlayer/AudioPlayer";
import CustomExpandableCard from "../../components/CustomExpandableCard/CustomExpandableCard";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import Header from "../../components/Header/Header";
import CustomCircleCard from "../../components/CustomCircleCard/CustomCircleCard";
import FeedbackDialog from "../../components/FeedbackDialog/FeedbackDialog";
import ResultPdfPage from "../../components/ResultPdfPage/ResultPdfPage";
import useFlutterEvent from "../../utils/useFlutterEvent";

const OrfResultScreen = ({
  heading,
  audioBlobForAudioPlayer,
  setAudioBlobForAudioPlayer,
  processedData,
  showOrfResultScreen,
  setShowOrfResultScreen,
  mode,
  showAssessmentListScreen,
  setShowAssessmentListScreen,
  resultScreenButtonText,
  assessmentId,
  setAssessmentId,
  t,
}) => {
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
  const [showPdfContent, setShowPdfContent] = useState(false);
  const [expandText, setExpandText] = useState(false);
  const [sendUserEvent, setSendUserEvent] = useState("");
  const [feedbackDialogOpened, setFeedbackDialogOpened] = useState(false);
  const { closeWebView } = useFlutterEvent();

  const handleAllAssessments = () => {
    setShowOrfResultScreen(false);
    setShowAssessmentListScreen(true);
  };

  const handleAllStudents = () => {
    if (feedbackDialogOpened) {
      closeWebView();
    } else {
      setShowFeedbackDialog(true);
    }
  };

  const handleShowPdfContent = () => {
    setShowPdfContent(true);
  };

  const wordsType = [
    t("incorrect_words"),
    t("missed_words"),
    t("correct_words"),
    t("extra_words"),
  ];

  const wordsTypeWordList = [
    processedData.result["incorrect_words"],
    processedData.result["missed_words"],
    processedData.result["correct_words"],
    processedData.result["extra_words"],
  ];

  return (
    <>
      {showPdfContent && (
        <div>
          <ResultPdfPage
            processedData={processedData}
            showPdfContent={showPdfContent}
            setShowPdfContent={setShowPdfContent}
            sendUserEvent={sendUserEvent}
            setSendUserEvent={setSendUserEvent}
            t={t}
          />
        </div>
      )}
      <div>
        <FeedbackDialog
          isDialogOpen={showFeedbackDialog}
          setDialogOpen={setShowFeedbackDialog}
          feedbackDialogOpened={feedbackDialogOpened}
          setFeedbackDialogOpened={setFeedbackDialogOpened}
          assessmentId={assessmentId}
          setAssessmentId={setAssessmentId}
          t={t}
        />
      </div>
      <Header
        background="linear-gradient(134.56deg, #1E5CD9 59.55%, #76BDF5 152.08%)"
        hideArrow="true"
        heading={heading}
        showShareDownloadIcon={true}
        showPdfContent={handleShowPdfContent}
        sendUserEvent={sendUserEvent}
        setSendUserEvent={setSendUserEvent}
      />
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "16px",
            fontWeight: "400",
            fontFamily: "Open Sans",
            marginTop: "7px",
            color: "#000",
            letterSpacing: "-0.304px",
          }}
        >
          {t("correct_word_per_minute")}
        </div>
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "16px",
            fontWeight: "400",
            fontFamily: "Open Sans",
            color: "#000",
            letterSpacing: "-0.304px",
          }}
        >
          {t("cwpm")}
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "8px",
            marginBottom: "24px",
          }}
        >
          <CustomCircleCard>
            <div
              style={{
                verticalAlign: "center",
                fontWeight: "400",
                color: "#000000",
              }}
            >
              <p
                style={{
                  fontSize: "33px",
                  fontFamily: "Open Sans",
                }}
              >
                {Math.round(processedData.result["cwpm"])}
              </p>
            </div>
          </CustomCircleCard>
        </div>
        <CustomCard>
          <div
            style={{
              fontWeight: "400",
              fontSize: "16px",
              padding: "12px 19px 12px 19px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "12px",
              }}
            >
              <div
                style={{
                  fontFamily: "Open Sans",
                }}
              >
                {t("words_read_aloud")}
              </div>
              <div
                style={{
                  fontFamily: "Open Sans",
                }}
              >
                {processedData.result["total_words_read"]}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  fontFamily: "Open Sans",
                }}
              >
                {t("total_no_of_words")}
              </div>
              <div
                style={{
                  fontFamily: "Open Sans",
                }}
              >
                {processedData.result["total_words"]}
              </div>
            </div>
          </div>
        </CustomCard>
        {
          <div
            style={{
              fontSize: "14px",
              fontWeight: "400",
              fontFamily: "Open Sans",
              color: "#373737",
              marginTop: "13px",
              marginLeft: "6%",
              marginRight: "6%",
            }}
          >
            {t("cwpm_in_last_assessment")}{" "}
            {processedData.result["previous_cwpm"]}
          </div>
        }
        {
          <div
            style={{
              marginTop: "12px",
            }}
          >
            <CustomCard>
              <div
                style={{
                  paddingRight: "20px",
                }}
              >
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    fontFamily: "Open Sans",
                    color: "#000",
                    marginTop: "10px",
                    marginLeft: "13px",
                    marginBottom: "12px",
                    letterSpacing: "-0.304px",
                  }}
                >
                  {t("paragraph")}
                </div>
                <div
                  style={{
                    marginLeft: "19px",
                    color: "#373737",
                    fontFamily: "Gopika",
                    letterSpacing: "-0.266px",
                    marginBottom:
                      audioBlobForAudioPlayer === null ? "16px" : "8px",
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitLineClamp: !expandText ? 3 : "",
                    WebkitBoxOrient: "vertical",
                    textOverflow: "ellipsis",
                    fontSize: "16px",
                  }}
                  onClick={() => setExpandText(!expandText)}
                >
                  {processedData.paragraph}
                </div>
                {audioBlobForAudioPlayer && (
                  <div
                    style={{
                      paddingBottom: "8px",
                    }}
                  >
                    <AudioPlayer
                      audioBlobForAudioPlayer={audioBlobForAudioPlayer}
                      setAudioBlobForAudioPlayer={setAudioBlobForAudioPlayer}
                      testedAudioElementRef={false}
                    />
                  </div>
                )}
              </div>
            </CustomCard>
          </div>
        }
        <div
          style={{
            marginTop: "12px",
          }}
        >
          {wordsType.map((wordType, index) => (
            <div
              key={index}
              style={{
                marginBottom: "12px",
              }}
            >
              <CustomExpandableCard
                header={wordType}
                wordCount={wordsTypeWordList[index].length}
                wordsList={wordsTypeWordList[index]}
                t={t}
              />
            </div>
          ))}
        </div>
        <div
          style={{
            textAlign: "center",
          }}
        >
          <Button
            variant="contained"
            startIcon={
              <KeyboardReturnIcon
                sx={{
                  paddingRight: "4px",
                }}
              />
            }
            style={{
              width: "90%",
              textTransform: "none",
              borderRadius: "12px",
              color: "#fff",
              fontWeight: "400",
              fontSize: "20px",
              margin: "12px 0px 20px 0px",
            }}
            onClick={() => {
              if (mode === "assessment") {
                handleAllStudents();
              } else if (mode === "performance") {
                handleAllAssessments();
              }
            }}
            disableElevation
          >
            {resultScreenButtonText}
          </Button>
        </div>
      </div>
    </>
  );
};

export default OrfResultScreen;
