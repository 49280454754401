import { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import "./FeedbackDialog.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import submitButtonIconWhite from "../../assets/submit_button_icon_white.svg";
import CustomLoadingDialog from "../CustomLoadingDialog/CustomLoadingDialog";
import { useEffect } from "react";
import useFlutterEvent from "../../utils/useFlutterEvent";
import submitFeedbackFormApi from "../../FetchApi/submitFeedbackFormApi";
import { UrlConstants } from "../../utils/commonUtils/constants";
import CustomErrorDialogBox from "../CustomErrorDialogBox/CustomErrorDialogBox";
import feedbackStarsImg from "../../assets/feedback_stars_bg_img.svg";
import FeedbackButtonList from "../FeedbackButtonList/FeedbackButtonList";
import { Trans } from "react-i18next";

const FeedbackDialog = ({
  isDialogOpen,
  setDialogOpen,
  feedbackDialogOpened,
  setFeedbackDialogOpened,
  assessmentId,
  setAssessmentId,
  t,
}) => {
  const [incorrectWordsSatisfactionLevel, setIncorrectWordsSatisfactionLevel] =
    useState("");
  const [missedWordsSatisfactionLevel, setMissedWordsSatisfactionLevel] =
    useState("");
  const [isFeedbackRecording, setIsFeedbackRecording] = useState(false);
  const [feedbackRecordedStatus, setFeedbackRecordedStatus] = useState(null);
  const [showLoadingDialog, setShowLoadingDialog] = useState(false);
  const [showButtonsInLoadingDialog, setShowButtonsInLoadingDialog] =
    useState(false);
  const [openDialogForError, setOpenDialogForError] = useState(false);
  const { closeWebView } = useFlutterEvent();

  const handleClose = () => {
    setDialogOpen(false);
    setFeedbackDialogOpened(true);
  };

  useEffect(() => {
    if (isFeedbackRecording && showLoadingDialog) {
      submitFeedbackFormApi(
        UrlConstants.BASE_URL +
          UrlConstants.BASE_PATH_URL +
          UrlConstants.ASSESSMENT +
          `/${assessmentId}` +
          "/feedback",
        incorrectWordsSatisfactionLevel,
        missedWordsSatisfactionLevel
      )
        .then((response) => {
          if (response.status === "ERROR") {
            setIsFeedbackRecording(false);
            setShowLoadingDialog(false);
            setFeedbackRecordedStatus(response.status);
            setOpenDialogForError(true);
          } else {
            setIsFeedbackRecording(false);
            setShowLoadingDialog(false);
            setFeedbackRecordedStatus(response.status);
            setDialogOpen(true);
          }
        })
        .catch((error) => {
          setIsFeedbackRecording(false);
          setShowLoadingDialog(false);
          setFeedbackRecordedStatus("ERR_INTERNET_DISCONNECTED");
          setOpenDialogForError(true);
        });
    }
  }, [isFeedbackRecording, showLoadingDialog]);

  useEffect(() => {
    if (feedbackRecordedStatus === "COMPLETED") {
      setTimeout(() => {
        closeWebView();
      }, 2000);
    }
  }, [feedbackRecordedStatus]);

  return (
    <>
      {feedbackRecordedStatus === "ERROR" ? (
        <CustomErrorDialogBox
          isDialogOpen={openDialogForError}
          setDialogOpen={setOpenDialogForError}
          handleCloseDialogForApi={"submitFeedbackApi"}
          warningMessage={t("error_occurred")}
          showRetryButton={false}
          containedButtonContent={t("retry")}
          textButtonContent={t("all_students")}
        />
      ) : (
        feedbackRecordedStatus === "ERR_INTERNET_DISCONNECTED" && (
          <CustomErrorDialogBox
            isDialogOpen={openDialogForError}
            setDialogOpen={setOpenDialogForError}
            handleCloseDialogForApi={"submitFeedbackApi"}
            warningMessage={t("error_occurred")}
            showRetryButton={false}
            containedButtonContent={t("retry")}
            textButtonContent={t("all_students")}
          />
        )
      )}

      {showLoadingDialog && isFeedbackRecording && (
        <CustomLoadingDialog
          isDialogOpen={showLoadingDialog}
          heading={t("sharing_feedback")}
          buttonFilledName={"Submit"}
          buttonFilledIcon={
            <img
              style={{
                width: "19px",
                height: "14px",
              }}
              src={submitButtonIconWhite}
              alt="submitButtonIconWhite"
            />
          }
          buttonTextName={"Skip"}
          showButtonsInLoadingDialog={showButtonsInLoadingDialog}
          setShowButtonsInLoadingDialog={setShowButtonsInLoadingDialog}
          dialogDescription={t("feedback_waiting_description")}
        />
      )}
      {!showLoadingDialog && !isFeedbackRecording && (
        <Dialog
          className="feedback"
          open={isDialogOpen}
          aria-labelledby="feedback-title"
          aria-describedby="feedback-dialog-description"
          style={{ borderRadius: "20px" }}
        >
          {isDialogOpen && feedbackRecordedStatus == null ? (
            <div
              className="feedback-dialog-title"
              style={{
                backgroundImage: `url(${feedbackStarsImg})`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                height: "120px",
              }}
            >
              <DialogTitle id="feedback-dialog-title">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      marginBottom: "4px",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Open Sans",
                        fontSize: "20px",
                        fontWeight: "600",
                        color: "#000000",
                        letterSpacing: "-0.019em",
                      }}
                    >
                      {t("feedback")}
                    </Typography>
                  </div>
                  <div>
                    <CloseOutlinedIcon
                      sx={{
                        position: "absolute",
                        height: "20px",
                        width: "20px",
                        right: "0",
                        color: "#1C1B1F",
                        marginRight: "15px",
                      }}
                      onClick={() => {
                        // closeWebView();
                        handleClose();
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    textAlign: "center",
                    margin: "0px 12px",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Open Sans",
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#373737",
                      letterSpacing: "-0.266px",
                    }}
                  >
                    {t("feedback_help_us_description")}
                  </Typography>
                </div>
              </DialogTitle>
            </div>
          ) : (
            isDialogOpen &&
            feedbackRecordedStatus == "COMPLETED" && (
              <div
                style={{
                  textAlign: "center",
                  marginBottom: "11px",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Open Sans",
                    fontSize: "20px",
                    fontWeight: "600",
                    color: "#000000",
                    letterSpacing: "-0.019em",
                    marginTop: "24px",
                  }}
                >
                  {t("feedback_recorded")}
                </Typography>
              </div>
            )
          )}
          <DialogContent>
            {isDialogOpen && feedbackRecordedStatus == null ? (
              <DialogContentText id="feedback-dialog-description">
                <div
                  style={{
                    display: "flex",
                    margin: "0px 14px 14px 14px",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#373737",
                      fontFamily: "Open Sans",
                      letterSpacing: "-0.304px",
                      marginRight: "8px",
                    }}
                  >
                    1.
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#373737",
                      fontFamily: "Open Sans",
                      letterSpacing: "-0.304px",
                    }}
                  >
                    <Trans i18nKey="incorrect_words_feedback" />
                  </Typography>
                </div>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <FeedbackButtonList
                    setSelectedValue={setIncorrectWordsSatisfactionLevel}
                    t={t}
                  />
                </div>
                <div
                  style={{
                    border: "0.9px solid #1E5CD9",
                    margin: "24px 44% 16px 44%",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    margin: "0px 14px 14px 14px",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#373737",
                      fontFamily: "Open Sans",
                      letterSpacing: "-0.304px",
                      marginRight: "8px",
                    }}
                  >
                    2.
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#373737",
                      fontFamily: "Open Sans",
                      letterSpacing: "-0.304px",
                    }}
                  >
                    <Trans i18nKey="missed_words_feedback" />
                  </Typography>
                </div>
                <div
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                  }}
                >
                  <FeedbackButtonList
                    setSelectedValue={setMissedWordsSatisfactionLevel}
                    t={t}
                  />
                </div>
                <div
                  style={{
                    margin: "24px 16px 12px 16px",
                  }}
                >
                  <Button
                    style={{
                      textTransform: "capitalize",
                      borderRadius: "12px",
                      width: "100%",
                      height: "42px",
                      color: "#FFF",
                    }}
                    variant="contained"
                    disableElevation
                    disabled={
                      incorrectWordsSatisfactionLevel === "" ||
                      missedWordsSatisfactionLevel === ""
                        ? true
                        : false
                    }
                    startIcon={
                      <img
                        style={{
                          width: "19px",
                          height: "14px",
                        }}
                        src={submitButtonIconWhite}
                        alt="submitButtonIconWhite"
                      />
                    }
                    onClick={() => {
                      handleClose();
                      setShowLoadingDialog(true);
                      setIsFeedbackRecording(true);
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Open Sans",
                        fontWeight: "400",
                        fontSize: "16px",
                        letterSpacing: "-0.019em",
                      }}
                    >
                      {t("submit")}
                    </div>
                  </Button>
                </div>
              </DialogContentText>
            ) : (
              isDialogOpen &&
              feedbackRecordedStatus == "COMPLETED" && (
                <>
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "32px",
                    }}
                  >
                    <CheckCircleIcon
                      style={{
                        color: "#7EB66B",
                        fontSize: "4rem",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      marginBottom: "14px",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Open Sans",
                        fontSize: "20px",
                        fontWeight: "400",
                        color: "#000000",
                        letterSpacing: "-0.019em",
                        marginTop: "24px",
                        marginLeft: "16px",
                        marginRight: "16px",
                      }}
                    >
                      {t("thanks_for_sharing_feedback")}
                    </Typography>
                  </div>
                </>
              )
            )}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default FeedbackDialog;
