import React from "react";
import Header from "../../components/Header/Header";
import ContentPasteOffOutlinedIcon from "@mui/icons-material/ContentPasteOffOutlined";
import { Button } from "@mui/material";
import assignmentAddLogo from "../../assets/assignment_add_logo.svg";
import "./NoAssessmentsScreen.css";

const NoAssessmentsScreen = ({
  studentName,
  t,
  showNoAssessmentsScreen,
  setShowNoAssessmentsScreen,
  showSplashLoadingScreen,
  setShowSplashLoadingScreen,
  currentMode, 
  setCurrentMode
}) => {
  const handleTakeTest = () => {
    setShowNoAssessmentsScreen(false);
    setShowSplashLoadingScreen(true);
    setCurrentMode("assessment");
  };
  return (
    <div>
      <Header
        heading={t("student_orf_test", {
          firstName: studentName.split(" ")[0],
        })}
        background="linear-gradient(134.56deg, #1E5CD9 59.55%, #76BDF5 152.08%)"
      />
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div
          style={{
            textAlign: "center",
            color: "#373737",
          }}
        >
          <ContentPasteOffOutlinedIcon
            style={{
              fontSize: "55",
            }}
          />
        </div>
        <div
          style={{
            fontSize: "16px",
            fontWeight: "600",
            color: "#000000",
            textAlign: "center",
            fontFamily: "Open Sans",
          }}
        >
          {t("no_assessment_description", {
            firstName: studentName.split(" ")[0],
          })}
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "20px",
          width: "100%",
        }}
      >
        <div
          style={{
            fontSize: "16px",
            fontWeight: "400",
            color: "#000",
            fontFamily: "Open Sans",
            letterSpacing: "-0.304px",
            marginLeft: "5.5%",
            marginRight: "5.5%",
          }}
        >
          {t("no_assessment_start_new_description", {
            firstName: studentName.split(" ")[0],
          })}
        </div>
        <div
          style={{
            textAlign: "center",
          }}
        >
          <Button
            variant="contained"
            startIcon={
              <img
                src={assignmentAddLogo}
                alt="assignmentAddLogog"
                style={{
                  paddingRight: "5px",
                }}
              />
            }
            disableElevation
            style={{
              width: "90%",
              textTransform: "none",
              borderRadius: "12px",
              color: "#fff",
              fontWeight: "400",
              fontSize: "20px",
              margin: "12px 0px 0px 0px",
              letterSpacing: "-0.38px",
              fontFamily: "Open Sans",
            }}
            onClick={() => {
              handleTakeTest();
            }}
          >
            {t("take_test")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NoAssessmentsScreen;
