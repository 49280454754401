import React from "react";
import Header from "../../components/Header/Header";
import CustomAssesmentCard from "../../components/CustomAssesmentCard/CustomAssesmentCard";
import getGroupedAssessmentList from "../../utils/commonUtils/getGroupedAssessmentList";
import { getDayFromDate } from "../../utils/commonUtils/commonUtils";

const AssessmentListScreen = ({
  studentName,
  paraId,
  assessmentListData,
  setAssessmentListData,
  showResultLoadingScreen,
  setShowResultLoadingScreen,
  showAssessmentListScreen,
  setShowAssessmentListScreen,
  assessmentId,
  setAssessmentId,
  cardClicked,
  setCardClicked,
  assessmentNumber,
  setAssessmentNumber,
  t,
}) => {
  const handleOnClickEvent = (cardAssessmentId, cardAssessmentNumber) => {
    setCardClicked(true);
    setAssessmentId(cardAssessmentId);
    setAssessmentNumber(cardAssessmentNumber);
    setShowAssessmentListScreen(false);
    setShowResultLoadingScreen(true);
  };

  const resultAssessmentList = getGroupedAssessmentList(assessmentListData);

  return (
    <div>
      <Header
        heading={t("student_orf_test", {
          firstName: studentName.split(" ")[0],
        })}
        background="linear-gradient(134.56deg, #1E5CD9 59.55%, #76BDF5 152.08%)"
      />
      {resultAssessmentList.map((assessment, index) => (
        <div
          key={index}
          style={{
            marginBottom: "40px",
          }}
        >
          <p
            style={{
              fontSize: "16px",
              fontFamily: "Open Sans",
              fontWeight: "600",
              color: "#373737",
              lineHeight: "27px",
              marginBottom: "10px",
              marginLeft: "1.3rem",
              marginTop: "0.2rem",
            }}
          >
            {assessment.month}
          </p>
          {assessment.assessments.map((item) => (
            <div
              key={item.id}
              style={{
                marginBottom: "16px",
              }}
            >
              <CustomAssesmentCard
                day={getDayFromDate(item.created_at, "numeric")}
                assessmentNumber={item.assessment_number}
                paraId={"DF4546F63D"}
                cwpm={item.cwpm}
                onClick={() =>
                  handleOnClickEvent(item.id, item.assessment_number)
                }
                t={t}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default AssessmentListScreen;
