import React, { useState, useRef, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import "./CustomBackdrop.css";
import holdThePhoneImg from "../../assets/hold_the_phone_img.svg";
import bringCloseToFaceImg from "../../assets/bring_close_to_face_img.svg";
import startReadingImg from "../../assets/start_reading.svg";
import { useTranslation } from "react-i18next";

const CustomBackdrop = ({ openBackdrop, setOpenBackdrop, t }) => {
  const [countDown, setCountDown] = useState(3);
  const intervalRef = useRef(null); // Reference to the interval
  const { i18n } = useTranslation();
  const backdropInstructionInfo = {
    3: {
      instruction: t("hold_the_phone"),
      instructionImage: holdThePhoneImg,
      instructionImageAlt: "holding-phone-img",
    },
    2: {
      instruction: t("bring_close_to_face"),
      instructionImage: bringCloseToFaceImg,
      instructionImageAlt: "bring-close-to-face-img",
    },
    1: {
      instruction: t("start_reading"),
      instructionImage: startReadingImg,
      instructionImageAlt: "start-reading-img",
    },
  };

  const handleClose = () => {
    setOpenBackdrop(false);
    clearInterval(intervalRef.current);
  };

  const handleOpen = () => {
    setOpenBackdrop(true);
    setCountDown(3); // Reset the countdown
    intervalRef.current = setInterval(() => {
      setCountDown((prevCount) => prevCount - 1);
    }, 2000);
  };

  useEffect(() => {
    if (openBackdrop) {
      handleOpen();
    }
  }, [openBackdrop]);

  useEffect(() => {
    if (countDown === 0) {
      clearInterval(intervalRef.current);
      // setTimeout(() => {
      handleClose();
      // },0);
    }
  }, [countDown]);

  return (
    <div>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          background: "rgba(0, 0, 0, 0.70)",
          backdropFilter: "blur(3px)",
        }}
        open={
          openBackdrop
        }
      >
        {countDown !== 0 && (
          <div
            style={{
              fontWeight: "800",
              textAlign: "center",
              letterSpacing: "-0.608px",
            }}
          >
            <div>
              <img
                style={{
                  width: "150px",
                  height: "125px",
                }}
                src={backdropInstructionInfo[countDown].instructionImage}
                alt={backdropInstructionInfo[countDown].instructionImageAlt}
              />
            </div>
            <div
              style={{
                fontSize: i18n.language === "en" ? "24px" : "30px",
                marginBottom: "10px",
              }}
            >
              {backdropInstructionInfo[countDown].instruction}
            </div>
            <div
              style={{
                fontSize: "80px",
              }}
            >
              {countDown}
            </div>
          </div>
        )}
      </Backdrop>
    </div>
  );
};

export default CustomBackdrop;
