import { Button, SwipeableDrawer } from "@mui/material";
import React from "react";
import bringCloseToFaceWhiteImg from "../../../assets/bring-close_to_face_white_img.svg";
import "./OnboardingDrawer.css";
import MicIcon from "@mui/icons-material/Mic";
import { useTranslation } from "react-i18next";
import QualityChecksInstruction from "../../QualityChecksInstruction/QualityChecksInstruction";

const OnboardingDrawer = ({ openDrawer, setOpenDrawer, t }) => {
  const { i18n } = useTranslation();
  const toggleDrawer = (open) => () => {
    setOpenDrawer(open);
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={openDrawer}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      onTouchStart={(event) => {
        event.nativeEvent.defaultMuiPrevented = true;
      }}
      sx={{
        background: "rgba(0, 0, 0, 0.25)",
      }}
      className="onboarding-drawer"
    >
      <div
        style={{
          marginTop: "38px",
          textAlign: "center",
        }}
      >
        <img
          style={{
            width: "154px",
            height: "95px",
          }}
          src={bringCloseToFaceWhiteImg}
          alt="bringCloseToFaceImg"
        />
      </div>
      <div
        style={{
          fontSize: i18n.language === "en" ? "16px" : "20px",
        }}
        className="check-microphone-quality-text"
      >
        {t("check_microphone_quality")}
      </div>
      <div
        style={{
          fontSize: i18n.language === "en" ? "14px" : "16px",
        }}
        className="audio-test-instruction-text"
      >
        {t("onboarding_test_recording_instruction")}
      </div>
      <div
        style={{
          margin: "20px 24px 0px",
        }}
      >
        <QualityChecksInstruction
          headingText={t("ensure_while_recording")}
          borderColor={"rgba(255, 255, 255, 0.43)"}
          headingColor={"#FFF"}
          contentColor={"#FFF"}
          t={t}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginBottom: "20px",
          marginTop: "30px",
        }}
      >
        <Button
          style={{
            height: "45px",
            background: "#FFF",
            width: "100%",
            borderRadius: "16px",
            textTransform: "capitalize",
            margin: "0px 16px",
            fontFamily: "Open Sans",
            fontWeight: "400",
            fontSize: "18px",
            letterSpacing: "-0.304px",
            color: "#2573D9",
          }}
          endIcon={
            <MicIcon
              sx={{
                marginLeft: "4px",
              }}
            />
          }
          size="50"
          variant="contained"
          onClick={() => {
            setOpenDrawer(false);
          }}
        >
          {t("start_recording")}
        </Button>
      </div>
    </SwipeableDrawer>
  );
};

export default OnboardingDrawer;
