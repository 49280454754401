import { useState, useRef } from "react";
import MicRecorder from "mic-recorder-to-mp3";

const useAudioRecorder = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [isStopped, setIsStopped] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const [isResumed, setIsResumed] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [error, setError] = useState(null);
  const [duration, setDuration] = useState(null);
  const mediaRecorderRef = useRef(null);

  const [mp3Recorder, setMp3Recorder] = useState(
    new MicRecorder({ bitRate: 128 })
  );

  const extractDuration = (audioBlob) => {
    if (audioBlob) {
      const audioElement = new Audio(URL.createObjectURL(audioBlob));
      audioElement.onloadedmetadata = () => {
        setDuration(audioElement.duration);
      };
    }
  };

  const handleStartRecording = () => {
    setError(null);
    mp3Recorder
      .start()
      .then(() => {
        setIsRecording(true);
        setIsStopped(false);
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
        setError("Error accessing microphone: ", error);
      });
  };

  const handleStopRecording = () => {
    mp3Recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        extractDuration(blob);
        setAudioBlob(blob);
        setIsRecording(false);
        setIsStopped(true);
      });
  };

  const handlePauseRecording = () => {
    if (mediaRecorderRef.current && isRecording && !isPaused) {
      mediaRecorderRef.current.pause();
      setIsRecording(false);
      setIsPaused(true);
    }
  };

  const handleResumeRecording = () => {
    if (mediaRecorderRef.current && isPaused) {
      mediaRecorderRef.current.resume();
      setIsRecording(true);
      setIsPaused(false);
    }
  };

  return {
    isRecording: isRecording,
    isStopped: isStopped,
    isPaused: isPaused,
    isResumed: isResumed,
    audioBlob: audioBlob,
    recordingError: error,
    duration: duration,
    setAudioBlob: setAudioBlob,
    startRecording: handleStartRecording,
    stopRecording: handleStopRecording,
    pauseRecording: handlePauseRecording,
    resumeRecording: handleResumeRecording,
  };
};
export default useAudioRecorder;
