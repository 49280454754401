import { useEffect, useState } from "react";

const createAssessmentApi = (url, requestBody) => {
  const options = {
    method: "POST",
    body: JSON.stringify(requestBody),
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "OPTIONS,POST,GET,PATCH",
      Authorization:
        "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0ZXN0X3VzZXIiLCJleHAiOjE3MTc2MDU4MDB9.m4J7eVK0R3jNGv37nE3OKl2Tt6tO7qbZ1eoHU81AzVs",
    },
  };

  return fetch(url, options).then((res) => {
    if (!res.ok) {
      return {
        status: "ERROR",
        data: "Error occurred...",
      };
    }
    return res.json();
  });
};

export default createAssessmentApi;
