import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import "./ErrorDialogBox.css";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import ErrorIcon from "@mui/icons-material/Error";
import RefreshIcon from "@mui/icons-material/Refresh";
import useFlutterEvent from "../../../utils/useFlutterEvent";
const ErrorDialogBox = ({
  isDialogOpen,
  setDialogOpen,
  showRetryButton,
  showAllStudentsButton,
  onClickRetryButton,
  onClickAllStudentsButton,
  t,
}) => {
  const { closeWebView } = useFlutterEvent();

  return (
    <Dialog
      className="error-dialog"
      open={isDialogOpen}
      aria-labelledby="delete-audio-title"
      style={{ borderRadius: "20px" }}
    >
      <DialogTitle
        id="delte-audio-dialog-title"
        style={{
          textAlign: "center",
        }}
      >
        <Typography
          style={{
            textAlign: "center",
            fontSize: "20px",
            paddingTop: "8px",
            color: "#000000",
            fontWeight: "400",
            letterSpacing: "-0.38px",
          }}
        >
          {t("error_occurred")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="save-result-dialog-description"
          style={{ textAlign: "center" }}
        >
          <ErrorIcon
            style={{
              color: "#D95959",
              borderRadius: "10px",
              marginTop: "16px",
              width: "80px",
              height: "80px",
            }}
          />
          {showRetryButton && (
            <div
              style={{
                display: "flex",
                marginTop: "22px",
              }}
            >
              <Button
                variant="contained"
                startIcon={<RefreshIcon />}
                style={{
                  textTransform: "none",
                  width: "100%",
                  borderRadius: "8px",
                  color: "#fff",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
                disableElevation
                onClick={() => {
                    onClickRetryButton();
                    setDialogOpen(false);
                }}
              >
                {t("retry")}
              </Button>
            </div>
          )}
          {showAllStudentsButton && (
            <div
              style={{
                display: "flex",
                marginTop: "8px",
              }}
            >
              <Button
                variant={showRetryButton ? "text" : "contained"}
                startIcon={
                  <KeyboardReturnIcon
                    sx={{
                      paddingRight: "2px",
                    }}
                  />
                }
                style={{
                  textTransform: "none",
                  width: "100%",
                  borderRadius: "8px",
                  color: showRetryButton ? "#111" : "#FFF",
                  fontWeight: "400",
                  fontSize: "16px",
                  textAlign: "center",
                  whiteSpace: "nowrap",
                }}
                onClick={() => {
                  closeWebView();
                }}
                disableElevation
              >
                {t("all_students")}
              </Button>
            </div>
          )}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default ErrorDialogBox;
