import { FlutterParams } from "./commonUtils/constants";
import {
  convertToTitleCase,
  getDecodedPayload,
} from "./commonUtils/commonUtils";

const useFlutterParamValues = () => {
  const urlParams = new URLSearchParams(window.location.search);

  let payload;
  try {
    payload = getDecodedPayload(urlParams.get("token"));
  } catch (error) {
    return {
      mode: "PageNotFound",
    };
  }
  
  const mode = payload[FlutterParams.MODE];

  return {
    studentName: convertToTitleCase(payload[FlutterParams.STUDENT_NAME]),
    studentId: payload[FlutterParams.STUDENT_ID],
    teacherId: payload[FlutterParams.TEACHER_ID],
    appLanguage: payload[FlutterParams.APP_LANGUAGE],
    assessmentLanguage: payload[FlutterParams.ASSESSMENT_LANGUAGE],
    grade: payload[FlutterParams.GRADE],
    organisationId: payload[FlutterParams.ORGANISATION_ID],
    deviceId: payload[FlutterParams.DEVICE_ID],
    lat: payload[FlutterParams.LATITUDE],
    long: payload[FlutterParams.LONGITUDE],
    schoolId: payload[FlutterParams.SCHOOL_ID],
    orfAudioTestValidationValue:
      payload[FlutterParams.ORF_AUDIO_TEST_VALIDATION_VALUE],
    mode:
      mode === "" || mode === null || mode === undefined
        ? "assessment"
        : mode === "performance" || mode === "assessment"
        ? mode
        : "PageNotFound",
  };
};

export default useFlutterParamValues;
