import React from "react";
import useAudioPlayer from "../../utils/useAudioPlayer";
import CustomAudioPlayer from "../../components/CustomAudioPlayer/CustomAudioPlayer";
import { useEffect } from "react";

function AudioPlayer({
  audioBlobForAudioPlayer,
  setAudioBlobForAudioPlayer,
  testedAudioElementRef,
  setTestedAudioElementRef,
}) {
  const {
    isPlaying,
    notPlaying,
    currentTime,
    duration,
    audioElementRef,
    handlePlayPause,
    handleTimeUpdate,
    handleLoadedMetadata,
    handleSliderChange,
    formatTime,
    setDuration,
  } = useAudioPlayer();

  useEffect(() => {
    if (audioElementRef !== null && testedAudioElementRef !== false) {
      setTestedAudioElementRef(audioElementRef);
    }
  }, [audioElementRef, testedAudioElementRef]);

  return (
    <>
      <CustomAudioPlayer
        isPlaying={isPlaying}
        notPlaying={notPlaying}
        currentTime={currentTime}
        duration={duration}
        audioElementRef={audioElementRef}
        handlePlayPause={handlePlayPause}
        handleTimeUpdate={handleTimeUpdate}
        handleLoadedMetadata={handleLoadedMetadata}
        handleSliderChange={handleSliderChange}
        formatTime={formatTime}
        audioBlobForAudioPlayer={audioBlobForAudioPlayer}
        setAudioBlobForAudioPlayer={setAudioBlobForAudioPlayer}
        setDuration={setDuration}
      />
    </>
  );
}

export default AudioPlayer;
