import React, { useEffect } from "react";
import html2pdf from "html2pdf.js";
import useFlutterEvent from "../../utils/useFlutterEvent";
import useFlutterParamValues from "../../utils/useFlutterParamValues";
import { getFormattedDate } from "../../utils/commonUtils/commonUtils";

const ResultPdfPage = ({
  processedData,
  showPdfContent,
  setShowPdfContent,
  sendUserEvent,
  setSendUserEvent,
  t,
}) => {
  const { sendDownloadFileEvent, sendShareFileEvent } = useFlutterEvent();
  const { studentName, studentId } = useFlutterParamValues();

  const downloadPdfContent = () => {
    const element = document.getElementById("pdf-content");
    html2pdf()
      .set({
        margin: 0.1,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        pagebreak: { mode: ["avoid-all", "css", "legacy"] },
      })
      .from(element)
      .outputPdf("blob")
      .then((pdfBlob) => {
        const reader = new FileReader();
        reader.readAsDataURL(pdfBlob);
        reader.onloadend = () => {
          const base64data = reader.result;
          if (sendUserEvent === "download") {
            sendDownloadFileEvent(base64data.split(",")[1]);
          } else if (sendUserEvent === "share") {
            sendShareFileEvent(base64data.split(",")[1]);
          }
        };
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
    setShowPdfContent(false);
  };

  useEffect(() => {
    if (showPdfContent) {
      downloadPdfContent();
    }
  }, [showPdfContent]);

  const wordsType = [
    t("incorrect_words"),
    t("missed_words"),
    t("correct_words"),
    t("extra_words"),
  ];

  const wordsTypeWordList = [
    processedData.result["incorrect_words"],
    processedData.result["missed_words"],
    processedData.result["correct_words"],
    processedData.result["extra_words"],
  ];

  return (
    <div id="pdf-content">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "100px",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            width: "90%",
            backgroundColor: "#FFFFFF",
            boxShadow: "4px 4px 16px rgba(0, 0, 0, 0.25)",
            borderRadius: "12px",
            position: "relative",
            background: "#F5F5F5",
            fontFamily: "Open Sans",
          }}
        >
          <div
            style={{
              fontWeight: "400",
              fontSize: "16px",
              padding: "12px 19px 12px 19px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "12px",
              }}
            >
              <div>{t("student_name")}</div>
              <div>{studentName}</div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "12px",
              }}
            >
              <div>{t("student_id")}</div>
              <div>{studentId}</div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "12px",
              }}
            >
              <div>{t("assessment_number")}</div>
              <div>{processedData["assessment_number"]}</div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>{t("date_of_assessment")}</div>
              <div>{getFormattedDate(processedData.created_at, "-", "numeric", "long")}</div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          fontSize: "16px",
          fontWeight: "400",
          fontFamily: "Open Sans",
          marginBottom: "2px",
        }}
      >
        {t("correct_word_per_minute")}
      </div>

      <p
        style={{
          display: "flex",
          justifyContent: "center",
          fontSize: "16px",
          fontWeight: "400",
          fontFamily: "Open Sans",
          color: "#000",
          letterSpacing: "-0.304px",
        }}
      >
        {t("cwpm")}
      </p>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
          marginBottom: "12px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#F5F5F5",
              boxShadow: "4px 4px 16px rgba(0, 0, 0, 0.25)",
              borderRadius: "100%",
              alignItems: "center",
              overflow: "hidden",
              height: "5.5rem",
              width: "5.5rem",
            }}
          >
            <div
              style={{
                verticalAlign: "center",
                fontFamily: "Open Sans",
                fontWeight: "400",
                color: "#000000",
              }}
            >
              <p
                style={{
                  fontSize: "33px",
                }}
              >
                {Math.round(processedData.result["cwpm"])}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            width: "90%",
            backgroundColor: "#FFFFFF",
            boxShadow: "4px 4px 16px rgba(0, 0, 0, 0.25)",
            borderRadius: "12px",
            position: "relative",
            background: "#F5F5F5",
          }}
        >
          <div
            style={{
              fontWeight: "400",
              fontSize: "16px",
              padding: "12px 19px 12px 19px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "12px",
              }}
            >
              <div
                style={{
                  fontFamily: "Open Sans",
                }}
              >
                {t("words_read_aloud")}
              </div>
              <div
                style={{
                  fontFamily: "Inter",
                }}
              >
                {processedData.result["total_words_read"]}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  fontFamily: "Open Sans",
                }}
              >
                {t("total_no_of_words")}
              </div>
              <div
                style={{
                  fontFamily: "Inter",
                }}
              >
                {processedData.result["total_words"]}
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        <div
          style={{
            fontSize: "14px",
            fontWeight: "400",
            fontFamily: "Open Sans",
            color: "#373737",
            marginTop: "6px",
            marginLeft: "6%",
          }}
        >
          {t("cwpm_in_last_assessment")} {processedData.result["previous_cwpm"]}
        </div>
      }

      {
        <div
          style={{
            marginTop: "6px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                width: "90%",
                backgroundColor: "#FFFFFF",
                boxShadow: "4px 4px 16px rgba(0, 0, 0, 0.25)",
                borderRadius: "12px",
                position: "relative",
                background: "#F5F5F5",
              }}
            >
              <div
                style={{
                  paddingRight: "20px",
                }}
              >
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    fontFamily: "Open Sans",
                    color: "#000000",
                    marginTop: "10px",
                    marginLeft: "13px",
                    marginBottom: "6px",
                    letterSpacing: "-0.266px",
                  }}
                >
                  {t("paragraph")}
                </div>
                <div
                  style={{
                    marginLeft: "19px",
                    color: "#373737",
                    fontFamily: "Open Sans",
                    letterSpacing: "-0.304px",
                    marginBottom: "16px",
                    fontSize: "16px",
                  }}
                >
                  {processedData.paragraph}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      {wordsType.map((wordType, index) => (
        <div
          key={index}
          style={{
            marginBottom: "2px",
          }}
        >
          <div
            style={{
              paddingTop: "10px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                style={{
                  width: "90%",
                  backgroundColor: "#FFFFFF",
                  boxShadow: "4px 4px 16px rgba(0, 0, 0, 0.25)",
                  borderRadius: "12px",
                  position: "relative",
                  background: "#F5F5F5",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingTop: "12px",
                    paddingLeft: "19px",
                    paddingBottom: "12px",
                    paddingRight: "21px",
                  }}
                >
                  <div
                    style={{
                      paddingTop: "3px",
                    }}
                  >
                    {wordType} - {wordsTypeWordList[index].length}
                  </div>
                </div>
                <div
                  style={{
                    marginLeft: "19px",
                    marginBottom: "8px",
                  }}
                >
                  <div
                    style={{
                      marginRight: "10px",
                    }}
                  >
                    {wordsTypeWordList[index].map((word, index) => (
                      <div
                        key={index}
                        style={{
                          display: "inline-block",
                          padding: "2px 6px",
                          background: "#D9D9D9",
                          borderRadius: "2px",
                          lineHeight: "30px",
                          margin: "4px 4px 3px 0px",
                        }}
                      >
                        {word}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ResultPdfPage;
