import ResultLoadingScreen from "./screens/ResultLoadingScreen/ResultLoadingScreen";
import { useState, useEffect } from "react";
import EnterOrfApplicationLoadingScreen from "./screens/EnterOrfApplicationLoadingScreen/EnterOrfApplicationLoadingScreen.js";
import OrfResultScreen from "./screens/OrfResultScreen/OrfResultScreen";
import OrfTestScreen from "./screens/OrfTestScreen/OrfTestScreen";
import AssessmentListScreen from "./screens/AssessmentListScreen/AssessmentListScreen.js";
import NoAssessmentsScreen from "./screens/NoAssessmentsScreen/NoAssessmentsScreen.js";
import useFlutterParamValues from "./utils/useFlutterParamValues.js";
import NotFoundPage from "./screens/NotFoundPage/NotFoundPage.js";
import { useTranslationSetup } from "./utils/useTranslationSetup.js";
import { useTranslation } from "react-i18next";
import AudioTestingScreen from "./screens/AudioTestingScreen/AudioTestingScreen.js";
import OrfOnboardingScreen from "./screens/OrfOnboardingScreen/OrfOnboardingScreen.js";
import InstructionScreen from "./screens/InstructionScreen/InstructionScreen.js";

function App() {
  const { i18n } = useTranslation();
  const { t } = useTranslationSetup();
  const {
    studentName,
    studentId,
    mode,
    appLanguage,
    orfAudioTestValidationValue,
  } = useFlutterParamValues();
  const [currentMode, setCurrentMode] = useState(mode);
  const [hideSlider, setHideSlider] = useState(false);
  const [hideArrow, setHideArrow] = useState(false);
  const [showSplashLoadingScreen, setShowSplashLoadingScreen] = useState(true);
  const [showOrfTestScreen, setShowOrfTestScreen] = useState(false);
  const [showResultLoadingScreen, setShowResultLoadingScreen] = useState(false);
  const [showOrfResultScreen, setShowOrfResultScreen] = useState(false);
  const [showInstructionScreen, setShowInstructionScreen] = useState(false);
  const [showOrfOnboardingScreen, setShowOrfOnboardingScreen] = useState(false);
  const [showAudioTestingScreen, setShowAudioTestingScreen] = useState(false);
  const [audioQualityFeedback, setAudioQualityFeedback] = useState(null);
  const [audioBlobForAudioPlayer, setAudioBlobForAudioPlayer] = useState(null);
  const [paraData, setParaData] = useState("");
  const [processedData, setProcessedData] = useState(null);
  const [assessmentId, setAssessmentId] = useState(null);
  const [orfResultStatus, setOrfResultStatus] = useState(null);
  const [uploadAudioStatus, setUploadAudioStatus] = useState(null);
  const [assessmentListData, setAssessmentListData] = useState(null);
  const [showAssessmentListScreen, setShowAssessmentListScreen] =
    useState(false);
  const [showNoAssessmentsScreen, setShowNoAssessmentsScreen] = useState(false);
  const [cardClicked, setCardClicked] = useState(false);
  const [assessmentNumber, setAssessmentNumber] = useState(null);
  const [audioValidationCreatedResponse, setAudioValidationCreatedResponse] =
    useState("");
  const [audioTestId, setAudioTestId] = useState(null);

  useEffect(() => {
    i18n.changeLanguage(appLanguage);
  }, [appLanguage]);

  useEffect(() => {
    if (assessmentListData !== null && assessmentListData.length > 0) {
      setShowSplashLoadingScreen(false);
      setShowAssessmentListScreen(true);
    } else if (assessmentListData !== null && assessmentListData.length === 0) {
      setShowSplashLoadingScreen(false);
      setShowNoAssessmentsScreen(true);
    }
  }, [assessmentListData]);

  useEffect(() => {
    if (
      orfAudioTestValidationValue === null ||
      orfAudioTestValidationValue === "Microphone quality is good" ||
      orfAudioTestValidationValue === "Microphone quality can be better" ||
      orfAudioTestValidationValue === undefined ||
      orfAudioTestValidationValue === "undefined"
    ) {
      if (paraData !== "" && audioValidationCreatedResponse !== "") {
        setShowSplashLoadingScreen(false);
        setShowOrfOnboardingScreen(true);
        setAudioTestId(audioValidationCreatedResponse["id"]);
      }
    } else if (
      orfAudioTestValidationValue !== null &&
      (orfAudioTestValidationValue !== "Microphone quality is good" ||
        orfAudioTestValidationValue !== "Microphone quality can be better" ||
        orfAudioTestValidationValue !== undefined ||
        orfAudioTestValidationValue !== "undefined")
    ) {
      if (paraData !== "") {
        setShowSplashLoadingScreen(false);
        setShowInstructionScreen(true);
      }
    }
  }, [paraData, audioValidationCreatedResponse]);

  useEffect(() => {
    if (processedData != null) {
      setShowResultLoadingScreen(false);
      setShowOrfTestScreen(false);
      setShowOrfResultScreen(true);
    }
  }, [processedData]);

  return (
    <div className="App">
      {/* *********************e******************** 404 NO PAGE FOUND ******************************** */}

      {currentMode === "PageNotFound" && <NotFoundPage />}

      {/* ************************************* PERFORMANCE FLOW ************************************** */}

      {showSplashLoadingScreen && currentMode === "performance" && (
        <EnterOrfApplicationLoadingScreen
          heading={t("oral_reading_fluency_assessment")}
          paraData={paraData}
          setParaData={setParaData}
          studentName={studentName}
          showSplashLoadingScreen={showSplashLoadingScreen}
          setShowSplashLoadingScreen={setShowSplashLoadingScreen}
          enrollmentNumber={studentId}
          assessmentListData={assessmentListData}
          setAssessmentListData={setAssessmentListData}
          studentId={studentId}
          mode={currentMode}
          t={t}
        />
      )}
      {showNoAssessmentsScreen &&
        !showSplashLoadingScreen &&
        currentMode === "performance" && (
          <NoAssessmentsScreen
            studentName={studentName}
            t={t}
            showNoAssessmentsScreen={showNoAssessmentsScreen}
            setShowNoAssessmentsScreen={setShowNoAssessmentsScreen}
            showSplashLoadingScreen={showSplashLoadingScreen}
            setShowSplashLoadingScreen={setShowSplashLoadingScreen}
            currentMode={currentMode}
            setCurrentMode={setCurrentMode}
          />
        )}
      {showAssessmentListScreen &&
        !showSplashLoadingScreen &&
        currentMode === "performance" && (
          <AssessmentListScreen
            studentName={studentName}
            assessmentListData={assessmentListData}
            setAssessmentListData={setAssessmentListData}
            showResultLoadingScreen={showResultLoadingScreen}
            setShowResultLoadingScreen={setShowResultLoadingScreen}
            showAssessmentListScreen={showAssessmentListScreen}
            setShowAssessmentListScreen={setShowAssessmentListScreen}
            assessmentId={assessmentId}
            setAssessmentId={setAssessmentId}
            cardClicked={cardClicked}
            setCardClicked={setCardClicked}
            assessmentNumber={assessmentNumber}
            setAssessmentNumber={setAssessmentNumber}
            t={t}
          />
        )}
      {showResultLoadingScreen && currentMode === "performance" && (
        <ResultLoadingScreen
          showResultLoadingScreen={showResultLoadingScreen}
          setShowResultLoadingScreen={setShowResultLoadingScreen}
          audioBlobForAudioPlayer={audioBlobForAudioPlayer}
          setAudioBlobForAudioPlayer={setAudioBlobForAudioPlayer}
          hideSlider={hideSlider}
          setHideSlider={setHideSlider}
          orfResultStatus={orfResultStatus}
          setOrfResultStatus={setOrfResultStatus}
          showOrfTestScreen={showOrfTestScreen}
          setShowOrfTestScreen={setShowOrfTestScreen}
          studentName={studentName}
          uploadAudioStatus={uploadAudioStatus}
          setUploadAudioStatus={setUploadAudioStatus}
          assessmentId={assessmentId}
          setAssessmentId={setAssessmentId}
          assessmentNumber={assessmentNumber}
          setAssessmentNumber={setAssessmentNumber}
          cardClicked={cardClicked}
          setCardClicked={setCardClicked}
          processedData={processedData}
          setProcessedData={setProcessedData}
          mode={currentMode}
          showAssessmentListScreen={showAssessmentListScreen}
          setShowAssessmentListScreen={setShowAssessmentListScreen}
          resultScreenButtonText={t("assessments")}
          t={t}
        />
      )}
      {showOrfResultScreen && currentMode === "performance" && (
        <OrfResultScreen
          heading={`${studentName.split(" ")[0]}: A${assessmentNumber} ${t(
            "result"
          )}`}
          audioBlobForAudioPlayer={audioBlobForAudioPlayer}
          setAudioBlobForAudioPlayer={setAudioBlobForAudioPlayer}
          processedData={processedData}
          showOrfResultScreen={showOrfResultScreen}
          setShowOrfResultScreen={setShowOrfResultScreen}
          mode={currentMode}
          showAssessmentListScreen={showAssessmentListScreen}
          setShowAssessmentListScreen={setShowAssessmentListScreen}
          resultScreenButtonText={t("assessments")}
          assessmentId={assessmentId}
          setAssessmentId={setAssessmentId}
          t={t}
        />
      )}

      {/* ********************************************** ORF FLOW ************************************* */}

      {showSplashLoadingScreen && currentMode === "assessment" && (
        <EnterOrfApplicationLoadingScreen
          heading={t("oral_reading_fluency_assessment")}
          paraData={paraData}
          setParaData={setParaData}
          studentName={studentName}
          showSplashLoadingScreen={showSplashLoadingScreen}
          setShowSplashLoadingScreen={setShowSplashLoadingScreen}
          audioValidationCreatedResponse={audioValidationCreatedResponse}
          setAudioValidationCreatedResponse={setAudioValidationCreatedResponse}
          enrollmentNumber={studentId}
          studentId={studentId}
          mode={currentMode}
          t={t}
        />
      )}
      {showOrfOnboardingScreen &&
        !showSplashLoadingScreen &&
        currentMode === "assessment" &&
        (orfAudioTestValidationValue === null ||
          orfAudioTestValidationValue === "Microphone quality is good" ||
          orfAudioTestValidationValue === "Microphone quality can be better" ||
          orfAudioTestValidationValue === undefined ||
          orfAudioTestValidationValue === "undefined") && (
          <OrfOnboardingScreen
            showOrfOnboardingScreen={showOrfOnboardingScreen}
            setShowOrfOnboardingScreen={setShowOrfOnboardingScreen}
            showAudioTestingScreen={showAudioTestingScreen}
            setShowAudioTestingScreen={setShowAudioTestingScreen}
            t={t}
          />
        )}
      {showInstructionScreen &&
        !showSplashLoadingScreen &&
        currentMode === "assessment" &&
        orfAudioTestValidationValue !== null &&
        (orfAudioTestValidationValue !== "Microphone quality is good" ||
          orfAudioTestValidationValue !== "Microphone quality can be better" ||
          orfAudioTestValidationValue !== undefined ||
          orfAudioTestValidationValue !== "undefined") && (
          <InstructionScreen
            studentName={studentName}
            showOrfTestScreen={showOrfTestScreen}
            setShowOrfTestScreen={setShowOrfTestScreen}
            showInstructionScreen={showInstructionScreen}
            setShowInstructionScreen={setShowInstructionScreen}
            t={t}
          />
        )}

      {/* ************************************ AUDIO TEST VALIDATION ********************************** */}
      {(orfAudioTestValidationValue === null ||
        orfAudioTestValidationValue === "Microphone quality is good" ||
        orfAudioTestValidationValue === "Microphone quality can be better" ||
        orfAudioTestValidationValue === undefined ||
        orfAudioTestValidationValue === "undefined") &&
        showAudioTestingScreen &&
        !showSplashLoadingScreen &&
        currentMode === "assessment" && (
          <AudioTestingScreen
            showOrfTestScreen={showOrfTestScreen}
            setShowOrfTestScreen={setShowOrfTestScreen}
            showAudioTestingScreen={showAudioTestingScreen}
            setShowAudioTestingScreen={setShowAudioTestingScreen}
            audioQualityFeedback={audioQualityFeedback}
            setAudioQualityFeedback={setAudioQualityFeedback}
            audioTestId={audioTestId}
            setAudioTestId={setAudioTestId}
            t={t}
          />
        )}

      {showOrfTestScreen &&
        !showSplashLoadingScreen &&
        currentMode === "assessment" && (
          <OrfTestScreen
            hideArrow={hideArrow}
            setHideArrow={setHideArrow}
            hideSlider={hideSlider}
            setHideSlider={setHideSlider}
            showResultLoadingScreen={showResultLoadingScreen}
            setShowResultLoadingScreen={setShowResultLoadingScreen}
            showOrfTestScreen={showOrfTestScreen}
            setShowOrfTestScreen={setShowOrfTestScreen}
            audioBlobForAudioPlayer={audioBlobForAudioPlayer}
            setAudioBlobForAudioPlayer={setAudioBlobForAudioPlayer}
            paragraph={paraData["paragraph"]}
            assessmentId={paraData["id"]}
            setAssessmentId={setAssessmentId}
            studentName={studentName}
            uploadAudioStatus={uploadAudioStatus}
            setUploadAudioStatus={setUploadAudioStatus}
            cardClicked={cardClicked}
            setCardClicked={setCardClicked}
            audioTestId={
              orfAudioTestValidationValue === null ||
              orfAudioTestValidationValue === "Microphone quality is good" ||
              orfAudioTestValidationValue ===
                "Microphone quality can be better" ||
              orfAudioTestValidationValue === undefined ||
              orfAudioTestValidationValue === "undefined"
                ? audioTestId
                : orfAudioTestValidationValue
            }
            t={t}
          />
        )}
      {showResultLoadingScreen && currentMode === "assessment" && (
        <ResultLoadingScreen
          showResultLoadingScreen={showResultLoadingScreen}
          setShowResultLoadingScreen={setShowResultLoadingScreen}
          audioBlobForAudioPlayer={audioBlobForAudioPlayer}
          setAudioBlobForAudioPlayer={setAudioBlobForAudioPlayer}
          hideSlider={hideSlider}
          setHideSlider={setHideSlider}
          orfResultStatus={orfResultStatus}
          setOrfResultStatus={setOrfResultStatus}
          showOrfTestScreen={showOrfTestScreen}
          setShowOrfTestScreen={setShowOrfTestScreen}
          studentName={studentName}
          uploadAudioStatus={uploadAudioStatus}
          setUploadAudioStatus={setUploadAudioStatus}
          assessmentId={paraData["id"]}
          setAssessmentId={setAssessmentId}
          cardClicked={cardClicked}
          setCardClicked={setCardClicked}
          processedData={processedData}
          setProcessedData={setProcessedData}
          mode={currentMode}
          resultScreenButtonText={t("all_students")}
          t={t}
        />
      )}
      {showOrfResultScreen && currentMode === "assessment" && (
        <OrfResultScreen
          heading={
            currentMode === "performance"
              ? `${studentName.split(" ")[0]}: Asessement ${assessmentNumber}`
              : t("student_orf_test", { firstName: studentName.split(" ")[0] })
          }
          audioBlobForAudioPlayer={audioBlobForAudioPlayer}
          setAudioBlobForAudioPlayer={setAudioBlobForAudioPlayer}
          processedData={processedData}
          showOrfResultScreen={showOrfResultScreen}
          setShowOrfResultScreen={setShowOrfResultScreen}
          mode={currentMode}
          resultScreenButtonText={t("all_students")}
          assessmentId={assessmentId}
          setAssessmentId={setAssessmentId}
          t={t}
        />
      )}
    </div>
  );
}

export default App;
