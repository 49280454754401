import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import "./DeleteAudioDialog.css";
import KeyboardVoiceRoundedIcon from "@mui/icons-material/KeyboardVoiceRounded";
import deleteIcon from "../../assets/delete.svg";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
const DeleteAudioDialog = ({
  isDialogOpen,
  setDialogOpen,
  hideSlider,
  setHideSlider,
  audioBlob,
  setAudioBlob,
  uploadAudioStatus,
  setUploadAudioStatus,
  t
}) => {

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <Dialog
      className="deleteAudio"
      open={isDialogOpen}
      aria-labelledby="delete-audio-title"
      style={{ borderRadius: "20px" }}
    >
      <DialogTitle
        id="delte-audio-dialog-title"
        style={{
          textAlign: "center",
          marginTop: "-8px"
        }}
      >
        <div
          style={{
            position: "relative",
            display: "inline-block",
          }}
        >
          <KeyboardVoiceRoundedIcon
            style={{
              padding: "12px 20px 12px 20px",
              color: "#fff",
              fontSize: "4rem",
              backgroundColor: "#949494",
              borderRadius: "10px",
              marginTop: "25px",
              width: "30px",
              height: "30px",
            }}
          />
          <CancelRoundedIcon
            style={{
              position: "absolute",
              color: "rgb(169 1 1 / 87%)",
              left: "53",
              top: "17",
              zIndex: "10",
              background: "#fff",
              borderRadius: "50%",
            }}
          />
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="save-result-dialog-description"
          style={{ textAlign: "center" }}
        >
          <Typography
            style={{
              textAlign: "center",
              fontSize: "20px",
              paddingTop: "5px",
              color: "#000000",
              fontWeight: "600",
            }}
          >
            {t('delete_audio')}
          </Typography>

          <Typography
            style={{
              textAlign: "center",
              fontSize: "14px",
              paddingTop: "5px",
              color: "#373737",
              fontWeight: "400",
            }}
          >
            {t('question_for_delete_audio_description')}
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "22px",
            }}
          >
            <Button
              className="deleteAudioIcon"
              variant="text"
              startIcon={<img src={deleteIcon} alt="deleteIcon" />}
              style={{
                textTransform: "none",
                width: "100%",
                borderRadius: "10px",
                fontWeight: "400",
                fontSize: "16px",
                color: "#E70000",
                whiteSpace: "nowrap",
                overflow: "inherit",
                textOverflow: "ellipsis"
              }}
              onClick={() => {
                handleClose();
                setHideSlider(false);
                setAudioBlob(null);
                setUploadAudioStatus(null);
              }}
            >
              {t('delete')}
            </Button>
            <Button
              variant="contained"
              startIcon={<CloseOutlinedIcon />}
              style={{
                textTransform: "none",
                width: "100%",
                borderRadius: "8px",
                color: "#fff",
                fontWeight: "400",
                fontSize: "16px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}
              onClick={handleClose}
              disableElevation
            >
              {t('cancel_delete_audio')}
            </Button>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteAudioDialog;
