import { useEffect, useState } from "react";

const fetchStatus = (url, abortController, pollingTimeout, pollingInterval) => {

  abortController.current = new AbortController();

  return new Promise((resolve, reject) => {
    (async function requestStatus(startTime = Date.now()) {
      if (Date.now() - startTime > pollingTimeout) {
        resolve({
          status: "UNDETERMINED",
          data: "Unusual long run",
        });
        return;
      }

      try {
        const response = await fetch(url, {
          headers: {
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0ZXN0X3VzZXIiLCJleHAiOjE3MTc2MDU4MDB9.m4J7eVK0R3jNGv37nE3OKl2Tt6tO7qbZ1eoHU81AzVs",
          },
          signal: abortController.current.signal
        });
        if (response.ok) {
          const data = await response.json();
          switch (data.status) {
            case "COMPLETED":
              resolve({
                status: "COMPLETED",
                data: data,
              });
              break;
            case "FAILED":
              resolve({
                status: "FAILED",
                data: data,
              });
              break;
            default:
              setTimeout(() => {
                requestStatus(startTime);
              }, pollingInterval);
          }
        } else {
          resolve({
            status: "ERROR",
            data: "Error occurred while processing data..",
          });
          return;
        }
      } catch (error) {
        if(abortController.current.signal.aborted){
          reject({
            status: "REQUEST_ABORTED",
            data: "Request aborted...",
          });
        }
        reject({
          status: "ERR_INTERNET_DISCONNECTED",
          data: "Network Error occurred...",
        });
      }
    })();
  });
};

export default fetchStatus;
