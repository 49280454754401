import React from "react";
import outOfChancesImg from "../../../assets/out_of_chances_img.svg";
import KeyboardReturnOutlinedIcon from "@mui/icons-material/KeyboardReturnOutlined";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import CustomDialog from "../CustomDialog/CustomDialog";
import CustomDialogContent from "../CustomDialogContent/CustomDialogContent";
import "./OutOfChancesDialog.css";
import useFlutterEvent from "../../../utils/useFlutterEvent";
import { Button } from "@mui/material";

const OutOfChancesDialog = ({
  open,
  setOpen,
  showOrfTestScreen,
  setShowOrfTestScreen,
  showAudioTestingScreen,
  setShowAudioTestingScreen,
  audioTestId,
  t,
}) => {
  const { closeWebView } = useFlutterEvent();
  const { sendOrfAudioTestFeedbackResult } = useFlutterEvent();
  return (
    <CustomDialog openDialog={open}>
      <CustomDialogContent>
        <div className="img-container">
          <img src={outOfChancesImg} alt="out-of-chances-img" />
        </div>
        <div className="out-of-chances-text">{t("mic_quality_poor")}</div>
        <div className="out-of-chances-desc">
          {t("mic_quality_poor_description")}
        </div>
        <div
          style={{
            margin: "8px 4% 0px",
          }}
        >
          <Button
            variant="contained"
            style={{
              height: "48px",
              textTransform: "none",
              width: "100%",
              borderRadius: "8px",
              fontWeight: "400",
              fontSize: "16px",
              textAlign: "center",
              whiteSpace: "nowrap",
              color: "#FFF",
              fontFamily: "Open Sans",
              lineHeight: "150%",
              letterSpacing: "-0.304px",
              border: "1px solid #2573D9",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            endIcon={
              <KeyboardReturnOutlinedIcon
                style={{
                  marginLeft: "2px",
                  fontSize: "24px",
                }}
              />
            }
            disableElevation
            onClick={() => {
              closeWebView();
              sendOrfAudioTestFeedbackResult(audioTestId);
            }}
          >
            {t("yes_change_my_device")}
          </Button>
        </div>
        <div
          style={{
            margin: "16px 4% 5px",
          }}
        >
          <Button
            variant="outlined"
            style={{
              height: "48px",
              textTransform: "none",
              width: "100%",
              borderRadius: "8px",
              fontWeight: "500",
              fontSize: "16px",
              textAlign: "center",
              whiteSpace: "nowrap",
              color: "#2573D9",
              fontFamily: "Open Sans",
              lineHeight: "150%",
              letterSpacing: "-0.304px",
              border: "1.45px solid #2573D9",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            endIcon={
              <EastOutlinedIcon
                style={{
                  fontSize: "24px",
                }}
              />
            }
            disableElevation
            onClick={() => {
              setShowAudioTestingScreen(false);
              setShowOrfTestScreen(true);
              sendOrfAudioTestFeedbackResult(audioTestId);
            }}
          >
            {t("no_continue_with_this_device")}
          </Button>
        </div>
        <div className="impact-the-results-text">
          {t("going_ahead_assessment_impact_results")}
        </div>
      </CustomDialogContent>
    </CustomDialog>
  );
};

export default OutOfChancesDialog;
