import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import "./AudioSizeShortError.css";
import ErrorIcon from "@mui/icons-material/Error";
import RefreshIcon from "@mui/icons-material/Refresh";

const AudioSizeShortError = ({
  isDialogOpen,
  setDialogOpen,
  warningHeading,
  warningMessage,
  onClickRecordAgain,
  t,
}) => {
  const handleCloseEventsForInvalidAudio = () => {
    onClickRecordAgain();
  };

  return (
    <Dialog
      className="audio-size-short-error"
      open={isDialogOpen}
      aria-labelledby="delete-audio-title"
    >
      <DialogTitle
        id="delte-audio-dialog-title"
        style={{
          textAlign: "center",
        }}
      >
        <ErrorIcon
          style={{
            color: "#D95959",
            borderRadius: "10px",
            marginTop: "16px",
            width: "80px",
            height: "80px",
          }}
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="save-result-dialog-description"
          style={{ textAlign: "center" }}
        >
          <Typography
            style={{
              textAlign: "center",
              fontSize: "20px",
              paddingTop: "8px",
              color: "#000000",
              fontWeight: "500",
              letterSpacing: "-0.38px",
              lineHeight: "150%",
            }}
          >
            {warningHeading}
          </Typography>
          <div
            style={{
              color: "#373737",
              textAlign: "center",
              fontFamily: "Open Sans",
              fontWeight: 400,
              fontSize: "4vw",
              lineHeight: "150%",
              marginTop: "4px",
              marginBottom: "14px",
              padding: "0px 8px",
            }}
          >
            {warningMessage}
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <Button
              variant={"contained"}
              startIcon={
                <RefreshIcon
                  style={{
                    paddingRight: "3px",
                    fontSize: "24px"
                  }}
                />
              }
              style={{
                textTransform: "none",
                width: "100%",
                borderRadius: "12px",
                color: "#FFF",
                fontWeight: "325",
                fontSize: "16px",
                textAlign: "center",
                whiteSpace: "nowrap",
                padding: "8px 0px"
              }}
              onClick={() => {
                handleCloseEventsForInvalidAudio();
              }}
              disableElevation
            >
              {t('record_again')}
            </Button>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default AudioSizeShortError;
