import React from "react";
import studentTeacherImg from "../../assets/student_teacher_img.svg";
import readParaAloudImg from "../../assets/read_para_alound_img.svg";
import avoidNoiceImg from "../../assets/avoid_noice_img.svg";
import audioRecordingMoreThan5SecImg from "../../assets/audio_recording_more_than_5_sec_img.svg";
import recheckMicWorkingImg from "../../assets/recheck_mic_working_img.svg";
import earImg from "../../assets/ear_img.svg";
import EastIcon from "@mui/icons-material/East";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import "./OrfOnboardingScreen.css";
import { Box, Button, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import useFlutterParamValues from "../../utils/useFlutterParamValues";
import useFlutterEvent from "../../utils/useFlutterEvent";

const OrfOnboardingScreen = ({
  showOrfOnboardingScreen,
  setShowOrfOnboardingScreen,
  showAudioTestingScreen,
  setShowAudioTestingScreen,
  t,
}) => {
  const instructionItemsList = [
    {
      instruction: t("instruct_student_to_read_para"),
      image: readParaAloudImg,
      alt: "img-1",
    },
    {
      instruction: t("avoid_noicy_environment"),
      image: avoidNoiceImg,
      alt: "img-2",
    },
    {
      instruction: t("audio_recording_more_than_5_sec"),
      image: audioRecordingMoreThan5SecImg,
      alt: "img-3",
    },
    {
      instruction: t("mic_working_properly"),
      image: recheckMicWorkingImg,
      alt: "img-4",
    },
    {
      instruction: t("listen_to_the_student_as_they_read"),
      image: earImg,
      alt: "img-5",
    },
  ];
  const { i18n } = useTranslation();
  const { closeWebView } = useFlutterEvent();
  const { orfAudioTestValidationValue } = useFlutterParamValues();

  return (
    <>
      <div
        style={{
          height: "100vh",
          overflow: "scroll",
        }}
      >
        <Box
          sx={{
            width: "50px",
            marginLeft: "2%",
            position: "relative",
            top: "2%",
          }}
        >
          <IconButton
            onClick={() => {
              closeWebView();
            }}
          >
            <KeyboardBackspaceRoundedIcon style={{ color: "#373737" }} />
          </IconButton>
        </Box>
        <div
          style={{
            textAlign: "center",
          }}
        >
          <img
            className="student-teacher-img"
            src={studentTeacherImg}
            alt="instruction-screen-img"
          />
        </div>
        <div className="heading-container">
          <div className="welcome-orf-text">{t("welcome_to_orf")}</div>
          <div className="teaching-companion-text">
            {t("your_teaching_companion")}
          </div>
        </div>
        <div className="about-container">
          <div className="about">{t("about")}</div>
          <div className="about-desc">{t("about_orf_description")}</div>
        </div>
        <div className="how-it-works-container">
          <div className="how-it-works-text">{t("how_it_works")}</div>
          <div className="instruction-container">
            {instructionItemsList.map((instructionItem, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "2vh",
                  alignItems: "center",
                }}
              >
                <div>
                  <img
                    style={{
                      height: "50%",
                      width:
                        index === 3 || index === 4
                          ? index === 3
                            ? "50%"
                            : "60%"
                          : "60%",
                      marginRight:
                        index === 3 || index === 4
                          ? index === 3
                            ? "13px"
                            : "16px"
                          : "8px",
                    }}
                    src={instructionItem.image}
                    alt={instructionItem.alt}
                  />
                </div>
                <div
                  style={{
                    fontFamily: "Open Sans",
                    fontSize: "3.8vw",
                    color: "#202124",
                    fontWeight: "425",
                    lineHeight: "21px",
                    width: "100%",
                    color: "#373737",
                    fontSize: i18n.language === "en" ? "3.8vw" : "4.4vw",
                  }}
                >
                  {instructionItem.instruction}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="lets-start-button">
        <Button
          style={{
            height: "42px",
            background: "#2573D9",
            width: "100%",
            borderRadius: "12px",
            textTransform: "capitalize",
            margin: "0px 16px",
            fontFamily: "Open Sans",
            fontWeight: "400",
            fontSize: i18n.language === "en" ? "16px" : "18px",
            letterSpacing: "-0.304px",
          }}
          endIcon={
            <EastIcon
              style={{
                marginLeft: "5px",
              }}
            />
          }
          variant="contained"
          onClick={() => {
            // if (orfAudioTestValidationValue === null) {
            setShowOrfOnboardingScreen(false);
            setShowAudioTestingScreen(true);
            // } else {
            //   setShowOrfOnboardingScreen(false);
            //   setShowOrfTestScreen(true);
            // }
          }}
        >
          {t("lets_start")}
        </Button>
      </div>
    </>
  );
};

export default OrfOnboardingScreen;
