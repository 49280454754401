import React from "react";
import Header from "../../components/Header/Header";
import TextResizer from "../../components/TextResizer/TextResizer";

const OrfTestScreen = ({
  hideArrow,
  setHideArrow,
  hideSlider,
  setHideSlider,
  showResultLoadingScreen,
  setShowResultLoadingScreen,
  showOrfTestScreen,
  setShowOrfTestScreen,
  audioBlobForAudioPlayer,
  setAudioBlobForAudioPlayer,
  paragraph,
  assessmentId,
  setAssessmentId,
  showHomeScreen,
  setShowHomeScreen,
  studentName,
  uploadAudioStatus,
  setUploadAudioStatus,
  cardClicked,
  setCardClicked,
  audioTestId,
  t,
}) => {
  return (
    <>
      <Header
        heading={t("student_orf_test", {
          firstName: studentName.split(" ")[0],
        })}
        color="#373737"
        hideArrow={hideArrow}
      />
      <TextResizer
        hideSlider={hideSlider}
        setHideSlider={setHideSlider}
        hideArrow={hideArrow}
        setHideArrow={setHideArrow}
        showResultLoadingScreen={showResultLoadingScreen}
        setShowResultLoadingScreen={setShowResultLoadingScreen}
        showOrfTestScreen={showOrfTestScreen}
        setShowOrfTestScreen={setShowOrfTestScreen}
        audioBlobForAudioPlayer={audioBlobForAudioPlayer}
        setAudioBlobForAudioPlayer={setAudioBlobForAudioPlayer}
        paragraph={paragraph}
        assessmentId={assessmentId}
        setAssessmentId={setAssessmentId}
        showHomeScreen={showHomeScreen}
        setShowHomeScreen={setShowHomeScreen}
        uploadAudioStatus={uploadAudioStatus}
        setUploadAudioStatus={setUploadAudioStatus}
        cardClicked={cardClicked}
        setCardClicked={setCardClicked}
        audioTestId={audioTestId}
        t={t}
      />
    </>
  );
};

export default OrfTestScreen;
