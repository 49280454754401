export const FlutterParams = Object.freeze({
  STUDENT_NAME: "student_name",
  STUDENT_ID: "student_id",
  TEACHER_ID: "teacher_id",
  APP_LANGUAGE: "app_language",
  ASSESSMENT_LANGUAGE: "assessment_language",
  GRADE: "grade",
  ORGANISATION_ID: "organisation_id",
  DEVICE_ID: "device_id",
  LATITUDE: "lat",
  LONGITUDE: "long",
  SCHOOL_ID: "school_id",
  ORF_AUDIO_TEST_VALIDATION_VALUE: "orf_audio_test_validation_value",
  MODE: "mode",
});

export const UrlConstants = Object.freeze({
  BASE_URL:
    process.env.NODE_ENV === "production"
      ? "https://orf-service.wadhwaniai.org/"
      : "https://orf-service-staging.wadhwaniai.org/",
  BASE_PATH_URL: "api/v1/orf/",
  ASSESSMENT: "assessment",
  VALIDATION: "validation",
});
