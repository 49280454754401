import React from "react";
import Header from "../../components/Header/Header";
import instructionScreenImg from "../../assets/instruction_screen_img.svg";
import readParaAloudImg from "../../assets/read_para_alound_img.svg";
import avoidNoiceImg from "../../assets/avoid_noice_img.svg";
import earImg from "../../assets/ear_img.svg";
import audioRecordingMoreThan5SecImg from "../../assets/audio_recording_more_than_5_sec_img.svg";
import recheckMicWorkingImg from "../../assets/recheck_mic_working_img.svg";
import { Button } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import { useTranslation } from "react-i18next";
import "./InstructionScreen.css";

const InstructionScreen = ({
  studentName,
  showOrfTestScreen,
  setShowOrfTestScreen,
  showInstructionScreen,
  setShowInstructionScreen,
  t,
}) => {
  const instructionItemsList = [
    {
      instruction: t("instruct_student_to_read_para"),
      image: readParaAloudImg,
      alt: "img-1",
    },
    {
      instruction: t("avoid_noicy_environment"),
      image: avoidNoiceImg,
      alt: "img-2",
    },
    {
      instruction: t("audio_recording_more_than_5_sec"),
      image: audioRecordingMoreThan5SecImg,
      alt: "img-3",
    },
    {
      instruction: t("mic_working_properly"),
      image: recheckMicWorkingImg,
      alt: "img-4",
    },
    {
      instruction: t("listen_to_the_student_as_they_read"),
      image: earImg,
      alt: "img-5",
    },
  ];
  const { i18n } = useTranslation();

  return (
    <div>
      <Header
        heading={t("student_orf_test", {
          firstName: studentName.split(" ")[0],
        })}
        color="#373737"
        hideArrow={false}
      />
      <div
        style={{
          textAlign: "center",
        }}
      >
        <img
          style={{
            height: "20%",
            width: "42%",
            marginTop: "0vh",
          }}
          src={instructionScreenImg}
          alt="instruction-screen-img"
        />
      </div>
      <div
        style={{
          fontFamily: "Open Sans",
          textAlign: "center",
          fontSize: i18n.language === "en" ? "5.5vw" : "6.5vw",
          color: "#202124",
          fontWeight: "600",
          lineHeight: "28px",
          letterSpacing: "-0.608px",
          marginBottom: "2vh",
        }}
      >
        {t("important_instructions")}
      </div>
      <div
        style={{
          margin: "0px 0px 80px",
        }}
      >
        {instructionItemsList.map((instructionItem, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "2vh",
              alignItems: "center",
            }}
          >
            <div>
              <img
                style={{
                  height: "50%",
                  width:
                    index === 3 || index === 4
                      ? index === 3
                        ? "55%"
                        : "70%"
                      : "60%",
                  marginRight: "8px",
                }}
                src={instructionItem.image}
                alt={instructionItem.alt}
              />
            </div>
            <div
              style={{
                fontFamily: "Open Sans",
                fontSize: "3.8vw",
                color: "#202124",
                fontWeight: "425",
                lineHeight: "21px",
                width: "65%",
                fontSize: i18n.language === "en" ? "3.8vw" : "4.4vw",
              }}
            >
              {instructionItem.instruction}
            </div>
          </div>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          position: "fixed",
          bottom: "0",
          left: "0",
          width: "100%",
        }}
        className="lets-start-button"
      >
        <Button
          style={{
            height: "42px",
            background: "#2573D9",
            width: "100%",
            borderRadius: "12px",
            textTransform: "capitalize",
            margin: "0px 16px",
            fontFamily: "Open Sans",
            fontWeight: "400",
            fontSize: i18n.language === "en" ? "16px" : "18px",
            letterSpacing: "-0.304px",
          }}
          endIcon={
            <EastIcon
              style={{
                marginLeft: "5px",
              }}
            />
          }
          variant="contained"
          onClick={() => {
            // if (orfAudioTestValidationValue === null) {
            //   setShowInstructionScreen(false);
            //   setShowAudioTestingScreen(true);
            // } else {
            setShowInstructionScreen(false);
            setShowOrfTestScreen(true);
            // }
          }}
        >
          {t("lets_start")}
        </Button>
      </div>
    </div>
  );
};

export default InstructionScreen;
