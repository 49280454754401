import React from "react";
import "./NotFoundPage.css";
import { Button } from "@mui/material";
import useFlutterEvent from "../../utils/useFlutterEvent";

const NotFoundPage = () => {
  const { closeWebView } = useFlutterEvent();
  return (
    <div className="not-found-page">
      <div className="not-found-page-content-container">
        <h1 className="error-code-not-found">404</h1>
        <p className="error-message-not-found">
          The application has undergone some recent changes. To continue, please
          update the app on the Google Play store
        </p>
        <Button
          className="error-page-not-found-button"
          style={{
            color: "#FFF",
            textTransform: "capitalize",
            borderColor: "#FFF",
            borderRadius: "12px",
          }}
          variant="outlined"
          onClick={() => closeWebView()}
        >
          Return to Student List
        </Button>
      </div>
    </div>
  );
};

export default NotFoundPage;
