import React, { useState } from "react";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import IconButton from "@mui/material/IconButton";
import "./CustomExpandableCard.css";
import CustomCard from "../CustomCard/CustomCard";

const CustomExpandableCard = ({ header, wordCount, wordsList, t }) => {
  const [isExpand, setExpand] = useState(false);
  const [seeMore, setSeeMore] = useState(false);

  const handleScroll = (event) => {
    setSeeMore(true);
  };

  return (
    <div
      style={{
        paddingTop: "10px",
      }}
    >
      <CustomCard>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "12px",
            paddingLeft: "19px",
            paddingBottom: "12px",
            paddingRight: "21px",
          }}
          onClick={() => {
            setExpand(!isExpand);
            setSeeMore(false);
          }}
        >
          <div
            style={{
              paddingTop: "2px",
              fontFamily: "Open Sans",
              fontSize: "16px",
              color: "#000",
              fontWeight: "600",
            }}
          >
            {header} - {wordCount}
          </div>
          <div className="expand-up-down">
            <IconButton
              style={{
                padding: "0px",
              }}
            >
              {isExpand ? (
                <ArrowDropUpOutlinedIcon />
              ) : (
                <ArrowDropDownOutlinedIcon />
              )}
            </IconButton>
          </div>
        </div>
        {isExpand && (
          <>
            <div
              style={{
                marginLeft: "19px",
                marginBottom:
                  wordsList.length > 0 && wordsList.length <= 25 && "8px",
                height:
                  wordsList.length > 25 ? (seeMore ? "400px" : "190px") : "",
                overflow: seeMore ? "scroll" : "scroll",
                transition: "height 0.5s linear",
              }}
              onScroll={handleScroll}
            >
              <div
                style={{
                  marginRight: "10px",
                }}
              >
                {wordsList.map((word, index) => (
                  <div
                    key={index}
                    style={{
                      display: "inline-block",
                      padding: "2px 6px",
                      background: "#D9D9D9",
                      borderRadius: "4px",
                      lineHeight: "30px",
                      margin: "4px 6px 6px 0px",
                      fontSize: "16px",
                    }}
                  >
                    {word}
                  </div>
                ))}
              </div>
            </div>
            {wordsList.length > 25 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "baseline",
                  boxShadow: "rgba(252, 252, 252, 1) 0px -13px 12px",
                  position: "absolute",
                  bottom: "0",
                  left: "0",
                  right: "0",
                  background: "white",
                  borderBottomLeftRadius: "12px",
                  borderBottomRightRadius: "12px",
                }}
                onClick={() => {
                  setSeeMore(!seeMore);
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    paddingBottom: "15px",
                    fontFamily: "Open Sans",
                    color: "#2573D9",
                    fontWeight: "620",
                    fontSize: "13px",
                  }}
                >
                  {seeMore ? t('see_less') : t('see_more')}
                </div>
                <div className="see-more-less">
                  <IconButton
                    style={{
                      padding: "0px 0px 0px 4px",
                      color: "#2573D9",
                    }}
                  >
                    {seeMore ? (
                      <ArrowDropUpOutlinedIcon />
                    ) : (
                      <ArrowDropDownOutlinedIcon />
                    )}
                  </IconButton>
                </div>
              </div>
            )}
          </>
        )}
      </CustomCard>
    </div>
  );
};

export default CustomExpandableCard;
