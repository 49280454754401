import React from "react";
import VolumeDownIcon from "@mui/icons-material/VolumeDown";
import "./AudioQualityComponent.css";

const AudioQualityComponent = ({ icon, text, color }) => {
  return (
    <div
      style={{
        textAlign: "center",
        color: color
      }}
    >
      {icon}
      <div
        className="audio-quality-text"
      >
        {text}
      </div>
    </div>
  );
};

export default AudioQualityComponent;
