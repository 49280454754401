import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import nipunBharatImage from "../../assets/nipun_bharat_image.jpg";
import { CircularProgress } from "@mui/material";
import createAssessmentApi from "../../FetchApi/createAssessmentApi";
import CustomErrorDialogBox from "../../components/CustomErrorDialogBox/CustomErrorDialogBox";
import fetchAssessmentsList from "../../FetchApi/fetchAssessmentsList";
import { UrlConstants } from "../../utils/commonUtils/constants";
import useFlutterParamValues from "../../utils/useFlutterParamValues";

const EnterOrfApplicationLoadingScreen = ({
  heading,
  enrollmentNumber,
  paraData,
  setParaData,
  studentName,
  showSplashLoadingScreen,
  setShowSplashLoadingScreen,
  assessmentListData,
  setAssessmentListData,
  audioValidationCreatedResponse,
  setAudioValidationCreatedResponse,
  studentId,
  mode,
  t,
}) => {
  const [assessmentCreatingStatus, setAssessmentCreatingStatus] =
    useState(null);
  const [audioValidationCreatingStatus, setAudioValidationCreatingStatus] =
    useState(null);
  const [openDialogForError, setOpenDialogForError] = useState(false);
  const {
    teacherId,
    assessmentLanguage,
    grade,
    organisationId,
    deviceId,
    lat,
    long,
    schoolId,
    orfAudioTestValidationValue,
  } = useFlutterParamValues();

  useEffect(() => {
    setTimeout(() => {
      if (mode === "assessment") {
        createAssessmentApi(
          UrlConstants.BASE_URL +
            UrlConstants.BASE_PATH_URL +
            UrlConstants.ASSESSMENT,
          {
            student_id: studentId,
            teacher_id: teacherId,
            lang: assessmentLanguage,
            grade: grade,
            organisation_id: organisationId,
            device_id: deviceId,
            school_id: schoolId,
            lat: lat,
            long: long,
          }
        )
          .then((response) => {
            if (response.status === "ERROR") {
              setOpenDialogForError(true);
              setAssessmentCreatingStatus(response.status);
            } else {
              setAssessmentCreatingStatus(response.status);
              setParaData(response);
            }
          })
          .catch((error) => {
            setOpenDialogForError(true);
            setAssessmentCreatingStatus("ERR_INTERNET_DISCONNECTED");
          });
      } else if (mode === "performance") {
        fetchAssessmentsList(
          UrlConstants.BASE_URL +
            UrlConstants.BASE_PATH_URL +
            UrlConstants.ASSESSMENT +
            `?student_id=${studentId}`
        )
          .then((response) => {
            if (response.status === "ERROR") {
              setOpenDialogForError(true);
              setAssessmentCreatingStatus(response.status);
            } else {
              setAssessmentListData(response);
            }
          })
          .catch((error) => {
            setOpenDialogForError(true);
            setAssessmentCreatingStatus("ERR_INTERNET_DISCONNECTED");
          });
      }
    }, 3000);
  }, [mode]);

  useEffect(() => {
    if (
      mode === "assessment" &&
      assessmentCreatingStatus === "CREATED" &&
      (orfAudioTestValidationValue === null ||
        orfAudioTestValidationValue === "Microphone quality is good" ||
        orfAudioTestValidationValue === "Microphone quality can be better" ||
        orfAudioTestValidationValue === undefined ||
        orfAudioTestValidationValue === "undefined")
    ) {
      createAssessmentApi(
        UrlConstants.BASE_URL +
          UrlConstants.BASE_PATH_URL +
          UrlConstants.VALIDATION,
        {
          teacher_id: teacherId,
          device_id: deviceId,
        }
      )
        .then((response) => {
          if (response.status === "ERROR") {
            setOpenDialogForError(true);
            setAudioValidationCreatingStatus(response.status);
          } else {
            setAudioValidationCreatedResponse(response);
            setAudioValidationCreatingStatus(response.status);
          }
        })
        .catch((error) => {
          setOpenDialogForError(true);
          setAudioValidationCreatingStatus("ERR_INTERNET_DISCONNECTED");
        });
    }
  }, [mode, assessmentCreatingStatus]);

  return (
    <>
      {assessmentCreatingStatus === "ERROR" ||
      audioValidationCreatingStatus === "ERROR" ? (
        <CustomErrorDialogBox
          isDialogOpen={openDialogForError}
          setDialogOpen={setOpenDialogForError}
          handleCloseDialogForApi={"createAssessmentApi"}
          studentName={studentName}
          showSplashLoadingScreen={showSplashLoadingScreen}
          setShowSplashLoadingScreen={setShowSplashLoadingScreen}
          warningMessage={t("error_occurred")}
          showRetryButton={false}
          containedButtonContent={t("retry")}
          textButtonContent={t("all_students")}
        />
      ) : (
        (assessmentCreatingStatus === "ERR_INTERNET_DISCONNECTED" ||
          audioValidationCreatingStatus === "ERR_INTERNET_DISCONNECTED") && (
          <CustomErrorDialogBox
            isDialogOpen={openDialogForError}
            setDialogOpen={setOpenDialogForError}
            handleCloseDialogForApi={"createAssessmentApi"}
            studentName={studentName}
            showSplashLoadingScreen={showSplashLoadingScreen}
            setShowSplashLoadingScreen={setShowSplashLoadingScreen}
            warningMessage={t("error_occurred")}
            showRetryButton={false}
            containedButtonContent={t("retry")}
            textButtonContent={t("all_students")}
          />
        )
      )}
      <div>
        <Header
          heading={heading}
          background="linear-gradient(134.56deg, #1E5CD9 59.55%, #76BDF5 152.08%)"
          hideArrow={true}
        />
        <div
          style={{
            fontFamily: "Open Sans",
            fontWeight: "400",
            marginTop: "20px",
            textAlign: "center",
            color: "#373737",
          }}
        >
          <div
            style={{
              fontSize: "20px",
            }}
          >
            {studentName}
          </div>
          <div
            style={{
              fontSize: "16px",
            }}
          >
            {enrollmentNumber}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "65vh",
            alignItems: "center",
          }}
        >
          <picture>
            <img
              style={{
                height: "302px",
                width: "250px",
                opacity: "0.5",
              }}
              src={nipunBharatImage}
              alt="nipunBharatLogo"
            />
          </picture>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "end",
              fontFamily: "Open Sans",
              fontWeight: "400",
              color: "#000000",
              marginTop: "16px",
            }}
          >
            {t("loading")}
            <div>
              <CircularProgress size="12px" background="black" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnterOrfApplicationLoadingScreen;
