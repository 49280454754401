import { Button } from "@mui/material";
import React, { useState } from "react";
import "./FeedbackButtonList.css"

const FeedbackButtonList = ({
    setSelectedValue,
    t
}) => {
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);
  const feedbackOptions = [
    {
      content: t('almost_all_words'),
      value: "Almost all words in the list",
    },
    {
      content: t('many_more_words'),
      value: "Many more words in the list",
    },
    {
      content: t('much_less_words'),
      value: "Much less words in the list",
    },
    {
      content: t('cant_say'),
      value: "Can’t Say",
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          marginLeft: "8px",
          marginRight: "8px"
        }}
        className="feedback-button-list"
      >
        {feedbackOptions.map((item, index) => (
          <Button
            key={index}
            style={{
              background:
                selectedButtonIndex === index ? "#2573D9" : "#DBEBFF",
              color: selectedButtonIndex === index ? "#FFF" : "#000",
              textAlign: "center",
              fontFamily: "Open Sans",
              fontSize: "12px",
              fontWeight: "400",
              lineHeight: "125%",
              letterSpacing: "-0.228px",
              textTransform: "capitalize",
              marginLeft: "4px",
              marginRight: "4px"
            }}
            onClick={() => {
              setSelectedButtonIndex(index);
              setSelectedValue(item.value);
            }}
          >
            {item.content}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default FeedbackButtonList;
