import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import "./SaveResultDialogBox.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import ErrorIcon from "@mui/icons-material/Error";
import savedSuccessfullyImage from "../../assets/saved_successfully_image.svg";
import useFlutterEvent from "../../utils/useFlutterEvent";
import CustomLoader from "../CustomLoader/CustomLoader";
import RefreshIcon from "@mui/icons-material/Refresh";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const SaveResultDialogBox = ({
  isDialogOpen,
  setDialogOpen,
  showResultLoadingScreen,
  setShowResultLoadingScreen,
  showOrfTestScreen,
  setShowOrfTestScreen,
  audioBlob,
  setAudioBlob,
  hideSlider,
  setHideSlider,
  showHomeScreen,
  setShowHomeScreen,
  uploadAudioStatus,
  setUploadAudioStatus,
  isAudioUploading,
  setAudioUploading,
  saveClicked,
  setSaveClicked,
  cardClicked,
  setCardClicked,
  t,
  abortController,
}) => {
  const { closeWebView } = useFlutterEvent();

  return (
    isDialogOpen && (
      <Dialog
        className="saveResult"
        open={isDialogOpen}
        aria-labelledby="save-result-title"
        aria-describedby="save-result-dialog-description"
        style={{ borderRadius: "20px" }}
      >
        {isDialogOpen &&
        isAudioUploading &&
        uploadAudioStatus != "ERR_INTERNET_DISCONNECTED" ? (
          <div
            style={{
              textAlign: "center",
              padding: "1.5rem 0px ",
            }}
          >
            <div
              style={{
                fontFamily: "Open Sans",
                margin: "0px",
                padding: "0px",
                marginBottom: "32px",
              }}
            >
              {t("saving_audio")}
            </div>
            <CustomLoader size={55} />
          </div>
        ) : isDialogOpen && uploadAudioStatus == "COMPLETED" ? (
          <DialogTitle
            id="save-result-dialog-title"
            style={{
              backgroundImage: `url(${savedSuccessfullyImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              marginTop: "0px",
            }}
          >
            <CheckCircleIcon
              style={{
                color: "#7EB66B",
                padding: "10% 0 2%",
                fontSize: "4rem",
              }}
            />
            <div
              className="recording-saved-text"
            >
              {t("recording_saved_successfully")}
            </div>
            <div
              className="you-can-assess-next-student-text"
            >
              {t("can_now_assess_next_student")}
            </div>
          </DialogTitle>
        ) : isDialogOpen && uploadAudioStatus == "ERROR" ? (
          <div
            style={{
              textAlign: "center",
            }}
          >
            <Typography
              style={{
                textAlign: "center",
                fontSize: "20px",
                paddingTop: "22px",
                color: "#000000",
                fontWeight: "400",
                letterSpacing: "-0.38px",
              }}
            >
              {t("error_occurred")}
            </Typography>
          </div>
        ) : (
          isDialogOpen &&
          uploadAudioStatus === "ERR_INTERNET_DISCONNECTED" && (
            <div
              style={{
                textAlign: "center",
              }}
            >
              <Typography
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  paddingTop: "22px",
                  color: "#000000",
                  fontWeight: "400",
                  letterSpacing: "-0.38px",
                }}
              >
                {t("error_occurred")}
              </Typography>
            </div>
          )
        )}
        <DialogContent>
          <DialogContentText
            id="save-result-dialog-description"
            style={{ textAlign: "center" }}
          >
            {(isDialogOpen && uploadAudioStatus == "COMPLETED") ||
            (isAudioUploading &&
              uploadAudioStatus != "ERR_INTERNET_DISCONNECTED") ? (
              <>
                <Button
                  variant="contained"
                  startIcon={
                    <KeyboardReturnIcon
                      sx={{
                        paddingRight: "2px",
                      }}
                    />
                  }
                  style={{
                    height: "44px",
                    textTransform: "none",
                    width: "100%",
                    borderRadius: "12px",
                    marginTop: "0.8rem",
                    marginBottom: "0.4rem",
                    fontWeight: "400",
                    fontSize: "16px",
                    textAlign: "center",
                    whiteSpace: "nowrap",
                  }}
                  disabled={isAudioUploading}
                  disableElevation
                  onClick={() => {
                    closeWebView();
                  }}
                >
                  {t("all_students")}
                </Button>
                <Button
                  variant="text"
                  startIcon={
                    isAudioUploading ? (
                      <CloseOutlinedIcon
                        sx={{
                          paddingRight: "2px",
                        }}
                      />
                    ) : (
                      <AssignmentOutlinedIcon
                        sx={{
                          paddingRight: "2px",
                        }}
                      />
                    )
                  }
                  style={{
                    textTransform: "none",
                    width: "100%",
                    borderRadius: "8px",
                    color: isAudioUploading ? "#000" : "#2573D9",
                    fontWeight: "400",
                    fontSize: "16px",
                    // opacity: isAudioUploading && "0.3",
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    border: !isAudioUploading && "2px solid #2573D9",
                    borderRadius: "12px",
                  }}
                  // disabled={isAudioUploading}
                  onClick={() => {
                    if (isAudioUploading) {
                      abortController.current &&
                        abortController.current.abort();
                      setDialogOpen(false);
                      setUploadAudioStatus(null);
                      setSaveClicked(false);
                      setAudioUploading(false);
                    } else {
                      setDialogOpen(false);
                      setShowOrfTestScreen(false);
                      setShowResultLoadingScreen(true);
                      setCardClicked(true);
                    }
                  }}
                >
                  {isAudioUploading ? t("cancel") : t("get_result")}
                </Button>
              </>
            ) : isDialogOpen && uploadAudioStatus == "ERROR" ? (
              <div>
                <ErrorIcon
                  style={{
                    color: "#D95959",
                    borderRadius: "10px",
                    marginTop: "26px",
                    width: "65px",
                    height: "65px",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    variant="contained"
                    startIcon={<RefreshIcon />}
                    style={{
                      textTransform: "none",
                      width: "100%",
                      borderRadius: "8px",
                      color: "#fff",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                    disableElevation
                    onClick={() => {
                      setDialogOpen(false);
                      setHideSlider(false);
                      setAudioBlob(null);
                      setUploadAudioStatus(null);
                      setSaveClicked(false);
                    }}
                  >
                    {t("retry")}
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: "8px",
                  }}
                >
                  <Button
                    variant="text"
                    startIcon={
                      <KeyboardReturnIcon
                        sx={{
                          paddingRight: "2px",
                        }}
                      />
                    }
                    style={{
                      textTransform: "none",
                      width: "100%",
                      borderRadius: "8px",
                      color: "#111",
                      fontWeight: "400",
                      fontSize: "16px",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                    onClick={() => {
                      // setDialogOpen(false);
                      closeWebView();
                    }}
                  >
                    {t("all_students")}
                  </Button>
                </div>
              </div>
            ) : (
              isDialogOpen &&
              uploadAudioStatus === "ERR_INTERNET_DISCONNECTED" && (
                <div>
                  <ErrorIcon
                    style={{
                      color: "#D95959",
                      borderRadius: "10px",
                      marginTop: "26px",
                      width: "65px",
                      height: "65px",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      variant="contained"
                      startIcon={<RefreshIcon />}
                      style={{
                        textTransform: "none",
                        width: "100%",
                        borderRadius: "8px",
                        color: "#fff",
                        fontWeight: "400",
                        fontSize: "16px",
                      }}
                      disableElevation
                      onClick={() => {
                        setDialogOpen(false);
                        setHideSlider(false);
                        setAudioBlob(null);
                        setUploadAudioStatus(null);
                        setSaveClicked(false);
                      }}
                    >
                      {t("retry")}
                    </Button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "8px",
                    }}
                  >
                    <Button
                      variant="text"
                      startIcon={
                        <KeyboardReturnIcon
                          sx={{
                            paddingRight: "2px",
                          }}
                        />
                      }
                      style={{
                        textTransform: "none",
                        width: "100%",
                        borderRadius: "8px",
                        color: "#111",
                        fontWeight: "400",
                        fontSize: "16px",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => {
                        // setDialogOpen(false);
                        closeWebView();
                      }}
                    >
                      {t("all_students")}
                    </Button>
                  </div>
                </div>
              )
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    )
  );
};
export default SaveResultDialogBox;
