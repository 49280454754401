import React from "react";
import CustomCard from "../CustomCard/CustomCard";
import "./CustomLoadingCard.css";

const CustomLoadingCard = ({ height, marginTop, marginBottom }) => {
  return (
    <div style={{
      opacity: 0.8,
      animation: "blinking 1.5s infinite",
    }}>
      <CustomCard>
        <div
          style={{
            marginTop: "10px",
            marginLeft: "12px",
            height: height,
            marginTop: marginTop,
            marginBottom: marginBottom
          }}
        >
        </div>
      </CustomCard>
    </div>
  );
};

export default CustomLoadingCard;
