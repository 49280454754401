import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "./translations/en.json";
import guTranslation from "./translations/gu.json";

const resources = {
  en: {
    translation: enTranslation,
  },
  gu: {
    translation: guTranslation,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "gu", // Set the default language here
  fallbackLng: "gu", // Fallback language if the desired language is not available
  interpolation: {
    escapeValue: false, // React already escapes values to prevent XSS attacks
  },
});

export default i18n;
