import { Button, SwipeableDrawer } from "@mui/material";
import React, { useState } from "react";
import listenToRecordingImg from "../../../assets/listen_to_recording_img.svg";
import "./AudioTestFeedbackDrawer.css";
import AudioPlayer from "../../AudioPlayer/AudioPlayer";
import QualityChecksInstruction from "../../QualityChecksInstruction/QualityChecksInstruction";

const AudioTestFeedbackDrawer = ({
  openDrawer,
  setOpenDrawer,
  submitAudio, 
  setSubmitAudio,
  isTestAudioProcessing, 
  setTestAudioProcessing,
  audioBlob,
  setAudioBlob,
  t,
}) => {
  const [testedAudioElementRef, setTestedAudioElementRef] = useState(null);
  const buttonEvent = [
    {
      selection: 0,
      content: t("submit"),
      value: "Microphone quality is good",
    },
    {
      selection: 1,
      content: t("retake"),
      value: "Microphone quality can be better",
    },
  ];

  const toggleDrawer = (open) => () => {
    if (!open) {
      return;
    }
    setOpenDrawer(open);
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={openDrawer}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      transitionDuration={1000}
      onTouchStart={(event) => {
        event.nativeEvent.defaultMuiPrevented = true;
      }}
      sx={{
        background: "rgba(0, 0, 0, 0.25)",
      }}
      className="audio-test-feedback-drawer"
    >
      <div className="listen-to-recording-container">
        <img
          style={{
            height: "4%",
            width: "4%",
            marginRight: "14px",
            color: "red",
          }}
          src={listenToRecordingImg}
          alt="avoidNoiceImg"
        />
        <div className="listen-to-recording-text">
          {t("listen_to_recording")}
        </div>
      </div>
      {audioBlob && (
        <div
          style={{
            padding: "8px 14px 8px 8px",
            background: "rgba(217, 217, 217, 0.54)",
            borderRadius: "12px",
            margin: "0px 14px 0px 14px",
          }}
        >
          <AudioPlayer
            audioBlobForAudioPlayer={audioBlob}
            setAudioBlobForAudioPlayer={setAudioBlob}
            testedAudioElementRef={testedAudioElementRef}
            setTestedAudioElementRef={setTestedAudioElementRef}
          />
        </div>
      )}
      <div
        style={{
          margin: "20px 20px 40px",
        }}
      >
        <QualityChecksInstruction
          headingText={t("ensure_while_listen_recording")}
          borderColor={"rgba(217, 217, 217, 0.8)"}
          headingColor={"#797B7C"}
          contentColor={"#A6A6A6"}
          t={t}
        />
      </div>
      <div
        style={{
          marginBottom: "8px",
        }}
      >
        {buttonEvent.map((item, index) => (
          <div key={index} className="button-container">
            <Button
              style={{
                height: "46px",
                width: "100%",
                borderRadius: "7px",
                textTransform: "inherit",
                margin: "0px 18px",
                fontFamily: "Open Sans",
                fontWeight: "500",
                fontSize: "16px",
                letterSpacing: "-0.304px",
                color: index === 0 ? "#FFF" : "#2573D9",
                whiteSpace: "nowrap",
                overflow: "inherit",
                textOverflow: "ellipsis",
                border: index === 1 && "1.2px solid #2573D9"
              }}
              size="50"
              variant={index === 0 ? "contained" : "outlined"}
              onClick={() => {
                if (testedAudioElementRef !== null) {
                  testedAudioElementRef.current?.pause();
                }
                if (index === 0) {
                  setTestAudioProcessing(true);
                  setSubmitAudio(true);
                } else {
                  setOpenDrawer(false);
                  setAudioBlob(null);
                }
              }}
              disableElevation
            >
              {item.content}
            </Button>
          </div>
        ))}
      </div>
    </SwipeableDrawer>
  );
};

export default AudioTestFeedbackDrawer;
