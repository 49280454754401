import React, { useState, useEffect, useRef } from "react";
import Header from "../../components/Header/Header";
import CustomLoadingCard from "../../components/CustomLoadingCard/CustomLoadingCard";
import CustomErrorDialogBox from "../../components/CustomErrorDialogBox/CustomErrorDialogBox";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { Button } from "@mui/material";
import fetchStatus from "../../FetchApi/fetchStatus";
import { UrlConstants } from "../../utils/commonUtils/constants";
import useFlutterEvent from "../../utils/useFlutterEvent";
import DelayResponseDialog from "../../components/Dialog/DelayResponseDialog/DelayResponseDialog";

const ResultLoadingScreen = ({
  showResultLoadingScreen,
  setShowResultLoadingScreen,
  audioBlobForAudioPlayer,
  setAudioBlobForAudioPlayer,
  hideSlider,
  setHideSlider,
  orfResultStatus,
  setOrfResultStatus,
  showOrfTestScreen,
  setShowOrfTestScreen,
  uploadAudioStatus,
  setUploadAudioStatus,
  assessmentId,
  setAssessmentId,
  processedData,
  setProcessedData,
  showOrfResultScreen,
  setShowOrfResultScreen,
  cardClicked,
  setCardClicked,
  mode,
  studentName,
  showAssessmentListScreen,
  setShowAssessmentListScreen,
  resultScreenButtonText,
  assessmentNumber,
  setAssessmentNumber,
  t,
}) => {
  const [openDialogForError, setOpenDialogForError] = useState(false);
  const [isContinueWaiting, setIsContinueWaiting] = useState(false);
  const { closeWebView } = useFlutterEvent();
  const abortController = useRef(null);
  const wordsTypeList = [
    "Incorrect words",
    "Missed words",
    "Correct words",
    "Extra words",
  ];

  const handleAllAssessments = () => {
    abortController.current && abortController.current.abort();
    setShowResultLoadingScreen(false);
    setShowAssessmentListScreen(true);
    setCardClicked(false);
    setOrfResultStatus(null);
  };

  const handleAllStudents = () => {
    abortController.current && abortController.current.abort();
    closeWebView();
  };

  useEffect(() => {
    if (cardClicked) {
      // setTimeout(() => {
      fetchStatus(
        UrlConstants.BASE_URL +
          UrlConstants.BASE_PATH_URL +
          UrlConstants.ASSESSMENT +
          `/${assessmentId}?get_audio=${
            // mode === "performance" ? true : false
            false
          }`,
        abortController,
        60000,
        8000
      )
        .then((data) => {
          if (data.status === "COMPLETED") {
            setProcessedData(data.data);
            setAssessmentId(data.data.id);
            setShowResultLoadingScreen(false);
            setShowOrfResultScreen(true);
            setOrfResultStatus(data.status);
          } else if (data.status === "UNDETERMINED") {
            setOrfResultStatus(data.status);
            setCardClicked(false);
          } else if (data.status === "FAILED") {
            setOrfResultStatus(data.status);
          } else if (data.status === "ERROR") {
            setOrfResultStatus(data.status);
          }
        })
        .catch((error) => {
          setOrfResultStatus(error.status);
        });
      // }, 3000);
    }
  }, [cardClicked]);

  useEffect(() => {
    if (
      orfResultStatus === "UNDETERMINED" ||
      orfResultStatus === "FAILED" ||
      orfResultStatus === "ERROR" ||
      orfResultStatus === "ERR_INTERNET_DISCONNECTED"
    ) {
      setOpenDialogForError(true);
    }
  }, [orfResultStatus]);

  return (
    <>
      {orfResultStatus === "UNDETERMINED" ? (
        <DelayResponseDialog
          open={openDialogForError}
          setOpen={setOpenDialogForError}
          titleMessage={t("process_taking_longer_than_usual")}
          titleDescription={t("delay_response_message")}
          buttonContent={t("all_students")}
          startIcon={<KeyboardReturnIcon />}
          onButtonClick={() => {
            closeWebView();
          }}
        />
      ) : orfResultStatus === "FAILED" ? (
        <CustomErrorDialogBox
          isDialogOpen={openDialogForError}
          setDialogOpen={setOpenDialogForError}
          handleCloseDialogForApi={"fetchOrfResultApi"}
          audioBlobForAudioPlayer={audioBlobForAudioPlayer}
          setAudioBlobForAudioPlayer={setAudioBlobForAudioPlayer}
          hideSlider={hideSlider}
          setHideSlider={setHideSlider}
          showResultLoadingScreen={showResultLoadingScreen}
          setShowResultLoadingScreen={setShowResultLoadingScreen}
          orfResultStatus={orfResultStatus}
          setOrfResultStatus={setOrfResultStatus}
          warningMessage={t("error_occurred")}
          uploadAudioStatus={uploadAudioStatus}
          setUploadAudioStatus={setUploadAudioStatus}
          showOrfTestScreen={showOrfTestScreen}
          setShowOrfTestScreen={setShowOrfTestScreen}
          mode={mode}
          showAssessmentListScreen={showAssessmentListScreen}
          setShowAssessmentListScreen={setShowAssessmentListScreen}
          showRetryButton={true}
          containedButtonContent={t("retry")}
          textButtonContent={t("all_students")}
          t={t}
        />
      ) : orfResultStatus === "ERROR" ? (
        <CustomErrorDialogBox
          isDialogOpen={openDialogForError}
          setDialogOpen={setOpenDialogForError}
          handleCloseDialogForApi={"fetchOrfResultApi"}
          audioBlobForAudioPlayer={audioBlobForAudioPlayer}
          setAudioBlobForAudioPlayer={setAudioBlobForAudioPlayer}
          hideSlider={hideSlider}
          setHideSlider={setHideSlider}
          showResultLoadingScreen={showResultLoadingScreen}
          setShowResultLoadingScreen={setShowResultLoadingScreen}
          orfResultStatus={orfResultStatus}
          setOrfResultStatus={setOrfResultStatus}
          warningMessage={t("error_occurred")}
          uploadAudioStatus={uploadAudioStatus}
          setUploadAudioStatus={setUploadAudioStatus}
          showOrfTestScreen={showOrfTestScreen}
          setShowOrfTestScreen={setShowOrfTestScreen}
          mode={mode}
          showAssessmentListScreen={showAssessmentListScreen}
          setShowAssessmentListScreen={setShowAssessmentListScreen}
          showRetryButton={true}
          containedButtonContent={t("retry")}
          textButtonContent={t("all_students")}
          t={t}
        />
      ) : (
        orfResultStatus === "ERR_INTERNET_DISCONNECTED" && (
          <CustomErrorDialogBox
            isDialogOpen={openDialogForError}
            setDialogOpen={setOpenDialogForError}
            handleCloseDialogForApi={"fetchOrfResultApi"}
            audioBlobForAudioPlayer={audioBlobForAudioPlayer}
            setAudioBlobForAudioPlayer={setAudioBlobForAudioPlayer}
            hideSlider={hideSlider}
            setHideSlider={setHideSlider}
            showResultLoadingScreen={showResultLoadingScreen}
            setShowResultLoadingScreen={setShowResultLoadingScreen}
            orfResultStatus={orfResultStatus}
            setOrfResultStatus={setOrfResultStatus}
            warningMessage={t("error_occurred")}
            uploadAudioStatus={uploadAudioStatus}
            setUploadAudioStatus={setUploadAudioStatus}
            showOrfTestScreen={showOrfTestScreen}
            setShowOrfTestScreen={setShowOrfTestScreen}
            mode={mode}
            showAssessmentListScreen={showAssessmentListScreen}
            setShowAssessmentListScreen={setShowAssessmentListScreen}
            showRetryButton={true}
            containedButtonContent={t("retry")}
            textButtonContent={t("all_students")}
            t={t}
          />
        )
      )}
      <div>
        <Header
          heading={
            mode === "performance"
              ? `${studentName.split(" ")[0]}: A${assessmentNumber} ${t(
                  "result"
                )}`
              : t("student_orf_test", { firstName: studentName.split(" ")[0] })
          }
          background="linear-gradient(134.56deg, #1E5CD9 59.55%, #76BDF5 152.08%)"
          hideArrow={true}
        />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              display: "inline-flex",
              textAlign: "center",
              backgroundColor: "#FFFFFF",
              boxShadow: "4px 4px 16px rgba(0, 0, 0, 0.25)",
              borderRadius: "100%",
              padding: "3rem",
              alignItems: "center",
              marginTop: "3.5vh",
              opacity: 0.8,
              animation: "blinking 1.5s infinite",
            }}
          ></div>
        </div>

        <div
          style={{
            marginTop: "3.5vh",
          }}
        >
          <CustomLoadingCard height={"12vh"} />
        </div>

        <div
          style={{
            marginTop: "7vh",
          }}
        >
          <CustomLoadingCard height={"14vh"} />
        </div>

        {wordsTypeList.map((_, index) => (
          <div
            key={index}
            style={{
              marginTop: "3vh",
            }}
          >
            <CustomLoadingCard height={"7vh"} />
          </div>
        ))}
        {/* {mode === "assessment" && ( */}
        <div
          style={{
            textAlign: "center",
          }}
        >
          <Button
            variant="contained"
            startIcon={
              <KeyboardReturnIcon
                sx={{
                  paddingRight: "4px",
                }}
              />
            }
            style={{
              width: "90%",
              textTransform: "none",
              borderRadius: "12px",
              color: "#fff",
              fontWeight: "400",
              fontSize: "20px",
              margin: "12px 0px 20px 0px",
            }}
            disableElevation
            onClick={() => {
              if (mode === "assessment") {
                handleAllStudents();
              } else if (mode === "performance") {
                handleAllAssessments();
              }
            }}
          >
            {resultScreenButtonText}
          </Button>
        </div>
      </div>
    </>
  );
};

export default ResultLoadingScreen;
