import { Dialog, DialogTitle } from "@mui/material";
import React from "react";
import "./CustomDialog.css";

const CustomDialog = ({ openDialog, setOpenDialog, children }) => {
  return (
    <Dialog
      className="custom-dialog"
      open={openDialog}
    >
        {children}
    </Dialog>
  );
};

export default CustomDialog;
