import React, { useState } from "react";
import CustomSlider from "../CustomSlider/CustomSlider";
import AudioRecorder from "../AudioRecorder/AudioRecorder";

const TextResizer = ({
  hideSlider,
  setHideSlider,
  hideArrow,
  setHideArrow,
  showResultLoadingScreen,
  setShowResultLoadingScreen,
  showOrfTestScreen,
  setShowOrfTestScreen,
  audioBlobForAudioPlayer,
  setAudioBlobForAudioPlayer,
  paragraph,
  assessmentId,
  setAssessmentId,
  showHomeScreen,
  setShowHomeScreen,
  uploadAudioStatus,
  setUploadAudioStatus,
  cardClicked,
  setCardClicked,
  audioTestId,
  t,
}) => {
  const [fontSize, setFontSize] = useState(7.5); // Initial font size of 7.5vw

  return (
    <div
      style={{
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "80vh",
          boxSizing: "border-box",
          overflow: "scroll",
          overflowX: "hidden",
          paddingLeft: "1rem",
          paddingRight: "1rem",
        }}
      >
        <p
          style={{
            fontSize: `${fontSize}vw`,
            textOverflow: "ellipsis",
            textSizeAdjust: "inherit",
            paddingBottom: "350px",
          }}
        >
          {paragraph}
        </p>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "0",
          left: "0",
          right: "0",
          zIndex: "5",
          background: "white",
          borderTop: "1px solid #C6C6C6",
          boxShadow: "0px -50px 50px rgba(252, 252, 247, 0.9)",
        }}
      >
        <div>
          {!hideSlider && (
            <div
              style={{
                paddingLeft: "40px",
                paddingRight: "40px",
              }}
            >
              <CustomSlider
                min={5}
                max={10}
                defaultValue={fontSize}
                step={0.01}
                setSliderValue={setFontSize}
                marks={[
                  {
                    value: 5,
                    label: t("a"),
                  },
                  {
                    value: 7.5,
                  },
                  {
                    value: 10,
                    label: t("a"),
                  },
                ]}
                t={t}
              />
            </div>
          )}
          <div>
            <AudioRecorder
              hideSlider={hideSlider}
              setHideSlider={setHideSlider}
              hideArrow={hideArrow}
              setHideArrow={setHideArrow}
              showResultLoadingScreen={showResultLoadingScreen}
              setShowResultLoadingScreen={setShowResultLoadingScreen}
              showOrfTestScreen={showOrfTestScreen}
              setShowOrfTestScreen={setShowOrfTestScreen}
              audioBlobForAudioPlayer={audioBlobForAudioPlayer}
              setAudioBlobForAudioPlayer={setAudioBlobForAudioPlayer}
              assessmentId={assessmentId}
              setAssessmentId={setAssessmentId}
              showHomeScreen={showHomeScreen}
              setShowHomeScreen={setShowHomeScreen}
              uploadAudioStatus={uploadAudioStatus}
              setUploadAudioStatus={setUploadAudioStatus}
              cardClicked={cardClicked}
              setCardClicked={setCardClicked}
              audioTestId={audioTestId}
              t={t}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextResizer;
