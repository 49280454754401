
const useFlutterEvent = () => {
  function closeWebView() {
    window.flutter_inappwebview.callHandler("closeWebView");
  }

  function sendDownloadFileEvent(base64PdfContent) {
    window.flutter_inappwebview.callHandler(
      "downloadFile",
      base64PdfContent
    );
  }

  function sendShareFileEvent(base64PdfContent) {
    window.flutter_inappwebview.callHandler(
      "shareFile",
      base64PdfContent
    );
  }

  function sendOrfAudioTestFeedbackResult(orfAudioTestFeedback) {
    window.flutter_inappwebview.callHandler(
      "getOrfAudioTestFeedbackResult",
      orfAudioTestFeedback
    );
  }

  return {
    closeWebView,
    sendDownloadFileEvent,
    sendShareFileEvent,
    sendOrfAudioTestFeedbackResult
  };
};

export default useFlutterEvent;
