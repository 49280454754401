import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import "./CustomErrorDialogBox.css";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import ErrorIcon from "@mui/icons-material/Error";
import RefreshIcon from "@mui/icons-material/Refresh";
import useFlutterEvent from "../../utils/useFlutterEvent";
const CustomErrorDialogBox = ({
  isDialogOpen,
  setDialogOpen,
  hideSlider,
  setHideSlider,
  audioBlobForAudioPlayer,
  setAudioBlobForAudioPlayer,
  handleCloseDialogForApi,
  showSplashLoadingScreen,
  setShowSplashLoadingScreen,
  showResultLoadingScreen,
  setShowResultLoadingScreen,
  orfResultStatus,
  setOrfResultStatus,
  warningMessage,
  uploadAudioStatus,
  setUploadAudioStatus,
  showOrfTestScreen,
  setShowOrfTestScreen,
  mode,
  showAssessmentListScreen,
  setShowAssessmentListScreen,
  showRetryButton,
  containedButtonContent,
  textButtonContent,
  isContinueWaiting,
  setIsContinueWaiting,
  cardClicked,
  setCardClicked,
  audioBlob,
  setAudioBlob,
  t,
}) => {
  const { closeWebView } = useFlutterEvent();

  const handleCloseEventsForCreateAssessmentApi = () => {
    setDialogOpen(false);
    setShowSplashLoadingScreen(false);
  };

  const handleContinueWaitingForFetchOrfResultApi = () => {
    setDialogOpen(false);
    setCardClicked(true);
    setIsContinueWaiting(true);
    setOrfResultStatus(null);
  };

  const handleCloseEventsForFetchOrfResultApi = () => {
    setDialogOpen(false);
    setAudioBlobForAudioPlayer(null);
    setShowResultLoadingScreen(false);
    setShowOrfTestScreen(true);
    setHideSlider(false);
    setOrfResultStatus(null);
    setUploadAudioStatus(null);
  };

  const handleNavigateToAssessmentsList = () => {
    setDialogOpen(false);
    setShowResultLoadingScreen(false);
    setShowAssessmentListScreen(true);
    setOrfResultStatus(null);
  };

  const handleCloseEventsForSubmitFeedbackApi = () => {
    closeWebView();
  };

  const handleCloseEventsForInvalidAudio = () => {
    setDialogOpen(false);
    setHideSlider(false);
    setAudioBlobForAudioPlayer(null);
    setAudioBlob(null);
  };

  return (
    <Dialog
      className="errorDialog"
      open={isDialogOpen}
      aria-labelledby="delete-audio-title"
      style={{ borderRadius: "20px" }}
    >
      <DialogTitle
        id="delte-audio-dialog-title"
        style={{
          textAlign: "center",
        }}
      >
        <Typography
          style={{
            textAlign: "center",
            fontSize: "20px",
            paddingTop: "8px",
            color: "#000000",
            fontWeight: "400",
            letterSpacing: "-0.38px",
          }}
        >
          {handleCloseDialogForApi === "fetchOrfResultApi"
            ? !isContinueWaiting
              ? warningMessage
              : t("error_occurred")
            : warningMessage}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="save-result-dialog-description"
          style={{ textAlign: "center" }}
        >
          <ErrorIcon
            style={{
              color: "#D95959",
              borderRadius: "10px",
              marginTop: "16px",
              width: "80px",
              height: "80px",
            }}
          />
          {showRetryButton && (
            <div
              style={{
                display: "flex",
                marginTop: "22px",
              }}
            >
              <Button
                variant="contained"
                startIcon={<RefreshIcon />}
                style={{
                  textTransform: "none",
                  width: "100%",
                  borderRadius: "8px",
                  color: "#fff",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
                disableElevation
                onClick={() => {
                  if (handleCloseDialogForApi === "createAssessmentApi") {
                    handleCloseEventsForCreateAssessmentApi();
                  } else if (handleCloseDialogForApi === "fetchOrfResultApi") {
                    if (mode === "performance") {
                      if (orfResultStatus !== "UNDETERMINED") {
                        handleNavigateToAssessmentsList();
                      } else {
                        if (isContinueWaiting) {
                          handleNavigateToAssessmentsList();
                        } else {
                          handleContinueWaitingForFetchOrfResultApi();
                        }
                      }
                    } else if (mode === "assessment") {
                      if (orfResultStatus !== "UNDETERMINED") {
                        handleCloseEventsForFetchOrfResultApi();
                      } else {
                        if (isContinueWaiting) {
                          handleCloseEventsForFetchOrfResultApi();
                        } else {
                          handleContinueWaitingForFetchOrfResultApi();
                        }
                      }
                    }
                  } else if (handleCloseDialogForApi === "submitFeedbackApi") {
                    handleCloseEventsForSubmitFeedbackApi();
                  } else if (handleCloseDialogForApi === "invalidAudio") {
                    handleCloseEventsForInvalidAudio();
                  }
                }}
              >
                {handleCloseDialogForApi === "fetchOrfResultApi"
                  ? orfResultStatus !== "UNDETERMINED"
                    ? t("retry")
                    : !isContinueWaiting
                    ? t("continue_waiting")
                    : t("retry")
                  : containedButtonContent}
              </Button>
            </div>
          )}
          <div
            style={{
              display: "flex",
              marginTop: "8px",
            }}
          >
            <Button
              variant={showRetryButton ? "text" : "contained"}
              startIcon={
                <KeyboardReturnIcon
                  sx={{
                    paddingRight: "2px",
                  }}
                />
              }
              style={{
                textTransform: "none",
                width: "100%",
                borderRadius: "8px",
                color: showRetryButton ? "#111" : "#FFF",
                fontWeight: "400",
                fontSize: "16px",
                textAlign: "center",
                whiteSpace: "nowrap",
              }}
              onClick={() => {
                closeWebView();
              }}
              disableElevation
            >
              {textButtonContent}
            </Button>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default CustomErrorDialogBox;
