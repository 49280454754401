import React from "react";

const uploadAudio = (url, audioBlobForAudioPlayer, abortController) => {
  abortController.current = new AbortController();
  const formData = new FormData();

  formData.append("file", audioBlobForAudioPlayer, "filename.mp3");

  const options = {
    signal: abortController.current.signal,
    method: "POST",
    body: formData,
    headers: {
      Authorization:
        "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0ZXN0X3VzZXIiLCJleHAiOjE3MTc2MDU4MDB9.m4J7eVK0R3jNGv37nE3OKl2Tt6tO7qbZ1eoHU81AzVs",
    },
  };

  return fetch(url, options).then((response) => {
    if (response.ok) {
      return {
        status: "COMPLETED",
      };
    } else {
      return {
        status: "ERROR",
      };
    }
  });
};

export default uploadAudio;
